import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Logo from 'components/Logo';
import Text from 'components/Text';
import Toast from 'components/Toast';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import axios from 'services/api/axios';
import logger, { useSetupLogger } from 'services/logger';
import { ManualReviewErrorCodes, loggerMessages } from 'types/logger';
import { getMessageFromError } from 'utils/errorMessage';
import { useTranslation } from 'react-i18next';
import AuthImage from 'components/AuthImage';
import useVerificationStore from 'stores/verificationStore';

interface ReviewData {
  email: string;
  message: string;
  idImage: string;
  idFileId: number | null;
  photoImage: string;
  photoFileId: number | null;
  identifier: string;
  tagRequestId: string;
  language: string;
}

interface CurrentStatus {
  status: null | 'invalid' | 'approved' | 'denied';
  timeStamp: string;
}

function ReviewRequest() {
  useSetupLogger(process.env.REACT_APP_HOST || window.location.origin);
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const data = searchParams.get('data');
  const networkId = useVerificationStore((s) => s.networkId);
  const [requestStatus, setRequestStatus] = useState({
    status: '',
    error: '',
  });
  const [reviewData, setReviewData] = useState<ReviewData>({
    email: '',
    message: '',
    idImage: '',
    idFileId: null,
    photoImage: '',
    photoFileId: null,
    identifier: '',
    tagRequestId: '',
    language: 'en',
  });
  const [currentStatus, setCurrentStatus] = useState<CurrentStatus>({
    status: null,
    timeStamp: '',
  });
  const [revealError, setRevealError] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const getCurrentReviewStatus = async () => {
      try {
        if (!reviewData.tagRequestId) {
          return;
        }

        const res = await axios.get(`/api/safe-passage/v1/emblem-integration/trust-requestor/tag-requests/${reviewData.tagRequestId}`);

        const { actions } = res.data;

        if (!actions.length) {
          return;
        }

        if (actions[0].description === 'revoked') {
          setCurrentStatus({ status: 'denied', timeStamp: new Date(actions[0].created).toLocaleDateString() });
        }
        if (actions[0].description === 'approved') {
          setCurrentStatus({ status: 'approved', timeStamp: new Date(actions[0].created).toLocaleDateString() });
        }
      } catch (err) {
        console.error(err);
        setCurrentStatus({ status: 'invalid', timeStamp: '' });
      }
    };
    getCurrentReviewStatus();
  }, [reviewData, reviewData.tagRequestId, t]);

  const decryptData = useCallback(async () => {
    if (!data || !networkId || !auth?.user?.access_token) return;

    try {
      const { data: responseData } = await axios.post(
        '/api/safe-passage/v1/emblem-integration/trust-authority/support/reveal-data',
        {
          data,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-network-id': networkId,
            authorization: `bearer ${auth?.user?.access_token}`,
          },
        },
      );

      setReviewData({
        email: responseData.data?.email ?? '',
        message: responseData.data?.message ?? '',
        idImage: responseData.data?.idImage ?? '',
        idFileId: responseData.data?.idFileId ?? null,
        photoImage: responseData.data?.photoImage ?? '',
        photoFileId: responseData.data?.photoFileId ?? null,
        identifier: responseData.data?.identifier ?? '',
        tagRequestId: responseData.data?.tagRequestId ?? '',
        language: responseData.data?.language ?? 'en',
      });
    } catch (e) {
      setRevealError('Error fetching manual review data');
      logger.error('Error fetching manual review data', { error: getMessageFromError(e) });
    }
  }, [auth?.user?.access_token, data, networkId]);

  const approveRequest = useCallback(async () => {
    try {
      setRequestStatus({
        status: 'submitting',
        error: '',
      });
      await axios.post(
        '/api/safe-passage/v1/emblem-integration/trust-authority/support/approve',
        {
          email: reviewData.email,
          identifier: reviewData.identifier,
          tagRequestId: reviewData.tagRequestId,
          idFileId: reviewData.idFileId,
          photoFileId: reviewData.photoFileId,
          language: reviewData.language,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-network-id': networkId,
            authorization: `bearer ${auth?.user?.access_token}`,
          },
        },
      );

      setRequestStatus({
        status: 'approved',
        error: '',
      });

      logger.info(loggerMessages.manualReview.info.approve, {
        appealFormMessage: reviewData.message,
        aggregates: {
          email: reviewData.email,
          identifier: reviewData.identifier,
          tagRequestId: reviewData.tagRequestId,
        },
      });
      logger.flush();
    } catch (err) {
      let error;
      if (err instanceof AxiosError && !!err?.response?.data?.errors[0]?.text) {
        error = err.response?.data.errors[0].text;
      } else {
        error = t('error.generic');
      }
      setRequestStatus({
        status: 'error',
        error,
      });
      logger.error(loggerMessages.manualReview.error.approve, {
        type: 'ERR_APPROVE_MANUAL_REVIEW',
        appealFormMessage: reviewData.message,
        email: reviewData.email,
        identifier: reviewData.identifier,
        errorMessage: getMessageFromError(err),
        tagRequestId: reviewData.tagRequestId,
        aggregates: {
          errorCode: ManualReviewErrorCodes.ERR_APPROVE_MANUAL_REVIEW,
        },
      });
    }
  }, [
    auth?.user?.access_token,
    networkId,
    reviewData.email,
    reviewData.identifier,
    reviewData.message,
    reviewData.tagRequestId,
    reviewData.idFileId,
    reviewData.photoFileId,
    reviewData.language,
    t,
  ]);

  const denyRequest = useCallback(async () => {
    try {
      setRequestStatus({
        status: 'submitting',
        error: '',
      });
      await axios.post(
        '/api/safe-passage/v1/emblem-integration/trust-authority/support/deny',
        {
          email: reviewData.email,
          identifier: reviewData.identifier,
          tagRequestId: reviewData.tagRequestId,
          idFileId: reviewData.idFileId,
          photoFileId: reviewData.photoFileId,
          language: reviewData.language,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-network-id': networkId,
            authorization: `bearer ${auth?.user?.access_token}`,
          },
        },
      );

      setRequestStatus({
        status: 'denied',
        error: '',
      });
      logger.info(loggerMessages.manualReview.info.deny, {
        appealFormMessage: reviewData.message,
        aggregates: {
          email: reviewData.email,
          identifier: reviewData.identifier,
          tagRequestId: reviewData.tagRequestId,
        },
      });
      logger.flush();
    } catch (err) {
      let error;
      if (
        err instanceof AxiosError
        && !!err?.response?.data?.errors?.[0]?.text
      ) {
        error = err.response?.data.errors[0].text;
      } else {
        error = t('error.generic');
      }
      setRequestStatus({
        status: 'error',
        error,
      });

      logger.error(loggerMessages.manualReview.error.deny, {
        type: 'ERR_DENY_MANUAL_REVIEW',
        appealFormMessage: reviewData.message,
        email: reviewData.email,
        identifier: reviewData.identifier,
        errorMessage: getMessageFromError(err),
        tagRequestId: reviewData.tagRequestId,
        aggregates: {
          errorCode: ManualReviewErrorCodes.ERR_DENY_MANUAL_REVIEW,
        },
      });
    }
  }, [
    auth?.user?.access_token,
    networkId,
    reviewData.email,
    reviewData.identifier,
    reviewData.message,
    reviewData.tagRequestId,
    reviewData.idFileId,
    reviewData.photoFileId,
    reviewData.language,
    t,
  ]);

  useEffect(() => {
    decryptData();
  }, [decryptData]);

  useEffect(() => {
    const { status } = requestStatus;
    if (!status || status === 'approved' || status === 'denied') { return () => { }; }
    const timeout = setTimeout(() => {
      setRequestStatus({ status: '', error: '' });
    }, 5000);

    return () => clearTimeout(timeout);
  }, [requestStatus]);

  if (revealError) {
    return (
      <div className="flex h-full items-center justify-center">
        <Text>{revealError}</Text>
      </div>
    );
  }

  if (!reviewData.email || !reviewData.message || !reviewData.idImage) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader className="h-20 w-20" />
      </div>
    );
  }

  if (
    requestStatus.status === 'approved'
    || requestStatus.status === 'denied'
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        {requestStatus.status === 'approved' ? (
          <CheckCircleIcon width={100} className="text-green-600" />
        ) : (
          <XCircleIcon width={100} className="text-red-600" />
        )}
        <h1 className="text-center text-2xl font-semibold">
          {requestStatus.status === 'approved'
            ? t('reviewRequest.requestApproved')
            : t('reviewRequest.requestDenied')}
        </h1>
      </div>
    );
  }

  if (!auth?.user?.access_token) {
    return (
      <div className="flex h-full items-center justify-center">
        <Text>{t('error.sessionExpired')}</Text>
      </div>
    );
  }

  return (
    <div className="px-6 py-8">
      <Logo className="mx-auto mb-6 h-8 w-auto" />
      <h1 className="mb-2 text-center text-2xl font-semibold">
        {t('reviewRequest.title')}
      </h1>
      <div className="flex justify-center">
        <div className="flex flex-col items-center">
          <h2 className="text-xl font-semibold">{t('reviewRequest.email')}</h2>
          <Text>{reviewData.email}</Text>
          <h2 className="text-xl font-semibold">{t('reviewRequest.message')}</h2>
          <Text>{reviewData.message}</Text>
        </div>
      </div>
      <div className="mt-10 flex flex-wrap justify-center gap-4">
        <div className="rounded-md border bg-slate-300 p-4 shadow-md sm:w-[17rem] md:w-[23rem]">
          <h2 className="text-center text-xl font-semibold text-black">
            {t('reviewRequest.idImage')}
          </h2>
          <AuthImage
            imageUrl={`/api/safe-passage/v1/asset?url=${encodeURIComponent(reviewData.idImage)}`}
            alt="ID"
            className="w-full"
          />
        </div>
        {reviewData.photoImage && (
          <div className="rounded-md border bg-slate-300 p-4 shadow-md sm:w-[17rem] md:w-[23rem]">
            <h2 className="text-center text-xl font-semibold text-black">
              {t('reviewRequest.userImage')}
            </h2>
            <AuthImage
              imageUrl={`/api/safe-passage/v1/asset?url=${encodeURIComponent(reviewData.photoImage)}`}
              alt="user"
              className="w-full"
            />
          </div>
        )}
      </div>

      {requestStatus.error && (
        <div className="mx-auto max-w-[50rem]">
          <Text className="mt-8 text-center text-red-600 flex p-2 shadow-lg bg-white rounded-md gap-2 justify-center max-w- mx-auto">
            <ExclamationCircleIcon width={25} className="font-bold" />
            {requestStatus.error}
          </Text>
        </div>
      )}

      {currentStatus.status && currentStatus.status !== 'invalid' && (
        <div className="mx-auto max-w-[50rem]">
          <Text className="mt-8 text-center text-slate-600 flex p-2 shadow-lg bg-white rounded-md gap-2 justify-center max-w- mx-auto">
            Request was
            {' '}
            {currentStatus.status}
            {' '}
            on
            {' '}
            {currentStatus.timeStamp}
          </Text>
        </div>
      )}

      {currentStatus.status === 'invalid' && (
        <div className="mx-auto max-w-[50rem]">
          <Text className="mt-8 text-center text-orange-600 flex p-2 shadow-lg bg-white rounded-md gap-2 justify-center max-w- mx-auto">
            <ExclamationCircleIcon width={25} className="font-bold" />
            Request is no longer valid.
          </Text>
        </div>
      )}

      {!currentStatus.status && (
        <div className="mt-6 flex justify-center gap-4">
          <Button
            className="w-full max-w-[23rem] !bg-green-600"
            onClick={() => approveRequest()}
          >
            {t('reviewRequest.approve')}
          </Button>
          <Button
            className="w-full max-w-[23rem] !bg-red-600"
            onClick={() => denyRequest()}
          >
            {t('reviewRequest.deny')}
          </Button>
        </div>
      )}

      <Toast
        className={clsx('pointer-events-none text-black transition-opacity', {
          'opacity-0': requestStatus.status !== 'submitting',
          'opacity-100': requestStatus.status === 'submitting',
        })}
        icon={<Loader className="w-[25px] text-primary-500" />}
        message={t('reviewRequest.processing')}
      />
    </div>
  );
}

export default ReviewRequest;
