import { create } from 'zustand';
import { FaceResult } from '@vladmandic/human';
import { FaceAngle } from 'services/human/types';

interface AgeDetectionStore {
  age: number | null;
  faces: FaceResult[];
  faceDetected: boolean;
  realScore: number;
  liveScore: number;
  similarity: number;
  isRetry: boolean;
  imageId: File | null;

  status: 'complete' | 'timedOut' | 'livenessFail' | null;
  centered: boolean;
  faceAngles: FaceAngle[];
  angles: { pitch: number; yaw: number };
  step: 1|2|3;
  ageResult: number;
  loopTime: number;
  submittingLoading: boolean;

  setAge: (age: number) => void;
  setImageId: (age: File | null) => void;
  setFaces: (faces: FaceResult[]) => void;
  setFaceDetected: (faceDetected: boolean) => void;
  setSimilarity: (newProgress: number) => void;
  setRealScore: (newRealScore: number) => void;
  setLiveScore: (newLiveScore: number) => void;
  setStatus: (status: 'complete' | 'timedOut' | null) => void;
  setCentered: (centered: boolean) => void;
  setAngles: (angles: { pitch: number; yaw: number }) => void;
  setFaceAngles: (faceAngles: FaceAngle[]) => void;
  setStep: (step: 1|2|3) => void;
  setIsRetry: (newIsRetry: boolean) => void;
  setAgeResult: (ageResult: number) => void;
  setLoopTime: (loopTime: number) => void;
  setSubmittingLoading: (submittingLoading: boolean) => void;
  reset: () => void
}

const useAgeDetectionStore = create<AgeDetectionStore>()((set) => ({
  age: null,
  faces: [],
  status: null,
  faceDetected: false,
  imageId: null,
  realScore: 0,
  liveScore: 0,
  similarity: 0,
  isRetry: false,
  centered: false,
  faceAngles: [],
  angles: { pitch: 0, yaw: 0 },
  step: 1,
  ageResult: 0,
  loopTime: 0,
  submittingLoading: false,

  setAge: (age) => set({ age }),
  setFaces: (faces) => set({ faces }),
  setFaceDetected: (faceDetected) => set({ faceDetected }),
  setImageId: (imageId) => set({ imageId }),
  setSimilarity: (newSimilarity) => set({ similarity: newSimilarity }),
  setRealScore: (newRealScore) => set({ realScore: newRealScore }),
  setLiveScore: (newLiveScore) => set({ liveScore: newLiveScore }),
  setStatus: (status) => set({ status }),
  setCentered: (centered) => set({ centered }),
  setAngles: (angles) => set({ angles }),
  setFaceAngles: (faceAngles) => set({ faceAngles }),
  setStep: (step) => set({ step }),
  setIsRetry: (newIsRetry) => set({ isRetry: newIsRetry }),
  setAgeResult: (ageResult) => set({ ageResult }),
  setLoopTime: (loopTime) => set({ loopTime }),
  setSubmittingLoading: (submittingLoading) => set({ submittingLoading }),
  reset: () => set({
    age: null,
    faces: [],
    status: null,
    faceDetected: false,
    realScore: 0,
    liveScore: 0,
    similarity: 0,
    imageId: null,
    centered: false,
    faceAngles: [],
    angles: { pitch: 0, yaw: 0 },
    step: 1,
    submittingLoading: false,
  }),
}));

export default useAgeDetectionStore;
