import { useEffect, useState } from 'react';
import { getMessageFromError } from 'utils/errorMessage';
import axios from 'services/api/axios';
import logger from 'services/logger';

function useCheckHealthStatus() {
  const [serverHealthy, setServerHealthy] = useState<boolean|null>(null);

  useEffect(() => {
    axios.get('/api/safe-passage/v1/verification/service-health')
      .then((response) => {
        if (response.data.status !== 'ok') {
          logger.error('Verification service is not healthy', { ...response.data });
          throw new Error('Verification service is not healthy');
        }
        logger.info('Verification service is healthy', { ...response.data });
        setServerHealthy(true);
      })
      .catch((err) => {
        const errorMessage = getMessageFromError(err);
        logger.error('Verification service is not healthy', { error: err, errorMessage });
        setServerHealthy(false);
      });
  }, []);

  return serverHealthy;
}

export default useCheckHealthStatus;
