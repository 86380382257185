import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Lottie, { EventListener } from 'react-lottie';
import useConfigStore from 'stores/configStore';
import liveCheck from 'components/lottie/live-check.json';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Text from 'components/Text';
import localStorageUtil from 'utils/localStorageUtil';
import { useTranslation } from 'react-i18next';
import mixpanel from 'services/mixpanel';

interface Props {
  initialized: boolean;
  animationComplete: boolean;
  setAnimationComplete: (value: boolean) => void;
  percentLoaded: number;
}

function LoadingPage({
  initialized,
  animationComplete,
  setAnimationComplete,
  percentLoaded,
}: Props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [messageIndex, setMessageIndex] = useState(0);
  const [isLoadingComplete, setIsComplete] = useState(false);
  const testVariant = useConfigStore((state) => state.testVariant);
  const { t, i18n } = useTranslation();
  const lng = searchParams.get('lng') ?? localStorageUtil.getValues().lng ?? '';

  const messages = [
    t('loadingPage.personal'),
    t('loadingPage.settingUp'),
    t('loadingPage.onDevice'),
  ];

  // If AB test is active remove last line about data staying on device
  if (testVariant === 'B') messages.pop();

  useEffect(() => {
    mixpanel.trackEvent({ event: 'Safe Passage Initializing' });
  }, []);

  useEffect(() => {
    if (lng) { i18n.changeLanguage(lng); }
  }, [i18n, lng]);

  useEffect(() => {
    if (!initialized) {
      return () => {};
    }

    setIsComplete(true);
    const timeout = setTimeout(() => {
      setAnimationComplete(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, [initialized, setAnimationComplete]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prev) => {
        if (prev === messages.length - 1) {
          return 0;
        }

        return prev + 1;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, [messages.length]);

  const eventListeners: EventListener[] = useMemo(() => ([
    {
      eventName: 'complete',
      callback: () => {
        setAnimationComplete(true);
      },
    },
  ]), [setAnimationComplete]);

  return (
    <div className="h-full flex flex-col justify-between overflow-x-hidden overflow-y-auto">
      <div>
        <Header includeBackButton={false} basePath="" />
        <main className="flex-1">
          <div className="flex px-8 mx-auto max-w-2xl min-h-full flex-col">
            <div className="relative mb-4 min-h-36">
              {messages.map((message, index) => (
                <Text
                  key={index}
                  className={clsx('font-bold text-center transition-opacity duration-1000 absolute top-0 left-0 right-0', {
                    'opacity-0': index !== messageIndex,
                    'opacity-100': index === messageIndex,
                  })}
                  size="2xl"
                >
                  {message}
                </Text>
              ))}
            </div>
            <div className="flex justify-center mb-4 h-[14rem] sm:h-[20rem] relative">

              {/**
               * Lottie doesn't handle passing in new props well.
               * If loop is true, we will not get the animate complete event.
               * We need to render two Lottie components to handle this.
               * The first one will play once and trigger the complete event. Then we hide it.
               * The second one will display after the first one is hidden then
               * loop indefinitely while the model is loading.
               * */}

              <Lottie
                style={{ display: animationComplete ? 'none' : 'block' }}
                options={{ animationData: liveCheck, autoplay: true, loop: false }}
                eventListeners={eventListeners}
                speed={2}
              />
              <Lottie
                style={{ display: animationComplete ? 'block' : 'none' }}
                options={{ animationData: liveCheck, autoplay: true, loop: true }}
                speed={2}
              />
            </div>
            <div className="mb-8 px-6 relative">
              <div
                className="mb-4 bg-primary-100 rounded-full transition-[width] ease-in-out"
                style={{
                  width: `${percentLoaded}%`,
                  height: '11px',
                  background: 'linear-gradient(90deg, #153B79, #1989E9, #DEEDFC)',
                }}
              />
              <div className="px-6 absolute top-0 left-0 w-full">
                <div
                  className="w-full rounded-full"
                  style={{
                    height: '11px',
                    background: '#D9D9D9',
                    opacity: 0.2,
                  }}
                />
              </div>

              <Text
                size="lg"
                className={clsx('text-center font-bold', { hidden: isLoadingComplete })}
              >
                {`${percentLoaded}%`}
              </Text>
              <Text
                size="lg"
                className={clsx('text-center font-bold transition-opacity duration-1000', {
                  'opacity-0': !isLoadingComplete,
                  'opacity-100': isLoadingComplete,
                  absolute: !isLoadingComplete,
                })}
              >
                {t('loadingPage.complete')}
              </Text>

            </div>
            <div className="px-4">
              <Text size="base" className="mb-4 text-center">
                {t('loadingPage.title')}
              </Text>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default LoadingPage;
