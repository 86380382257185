import { LivenessCheckConfig } from 'stores/configStore';

function selectLivenessConfig(configs: LivenessCheckConfig[]) {
  let c = configs[0];

  if (configs.length === 1) {
    return c;
  }

  const random = Math.random();
  let current = 0;
  for (const config of configs) {
    if (!config.traffic || config.traffic <= 0) {
      break;
    }

    current += config.traffic;
    if (random < current) {
      c = config;
      break;
    }
  }

  return c;
}

export default selectLivenessConfig;
