import { Config } from '@vladmandic/human';

export const config: Partial<Config> = {
  // debug: true,
  backend: 'webgl',
  gesture: {
    enabled: false,
  },
  hand: {
    enabled: false,
  },
  body: {
    enabled: false,
  },
  segmentation: {
    enabled: false,
  },
  object: {
    enabled: false,
  },
  face: {
    antispoof: {
      enabled: true,
    },
    liveness: {
      enabled: true,
    },
    iris: {
      enabled: false,
    },
    emotion: {
      enabled: false,
    },
    mesh: {
      enabled: true,
    },
    detector: {
      rotation: true,
      return: false,
      skipFrames: 50,
    },
    description: {
      enabled: true,
    },
  },
};

export const faceDetectOnlyConfig: Partial<Config> = {
  backend: 'webgl',
  gesture: {
    enabled: false,
  },
  hand: {
    enabled: false,
  },
  body: {
    enabled: false,
  },
  segmentation: {
    enabled: false,
  },
  object: {
    enabled: false,
  },
  face: {
    antispoof: {
      enabled: false,
    },
    liveness: {
      enabled: false,
    },
    iris: {
      enabled: false,
    },
    emotion: {
      enabled: false,
    },
    mesh: {
      enabled: false,
    },
    detector: {
      rotation: true,
      return: false,
      skipFrames: 50,
    },
    description: {
      enabled: false,
    },
  },
};
