import React, {
  useId, useCallback, useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHumanAgePredictor } from 'contexts/HumanAgePredictionContext';

import stopStream from 'utils/stopStream';
import setVideoSource from 'utils/setVideoSource';

import useAgeDetectionStore from 'stores/ageDetectionStore';
import useConfigStore from 'stores/configStore';

import logger from 'services/logger';
import mixpanel from 'services/mixpanel';

import { AgePredictionErrorCodes, loggerMessages } from 'types/logger';
import useProgressStore from 'stores/progressStore';
import liveCheck from 'components/lottie/live-check-2.json';

// import EulerArcSVG from './svgs/EulerArcSVG';
import FullscreenLoader from './FullscreenLoader';
import Text from './Text';
import CompleteCircleSVG from './svgs/CompleteCircleSVG';
// import RotationSVG from './svgs/RotationSVG';
import ProgressArcSVG from './svgs/ProgressArcSVG';
import FaceAngleMessage from './FaceAngleMessage';
import CircleMaskSVG from './svgs/CircleMaskSVG';

interface Props {
  setShowDemo: (value: boolean) => void
  demoCount: number;
  isServer: boolean;
}

function AgePrediction({ setShowDemo, demoCount, isServer }:Props) {
  const id = useId();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [videoElReady, setVideoElReady] = useState(false);
  const [displayDemo, setDisplayDemo] = useState(false);
  const [demoPaused, setDemoPaused] = useState(true);
  const stallCount = useRef(0);
  const { t } = useTranslation();

  const status = useAgeDetectionStore((state) => state.status);
  const centered = useAgeDetectionStore((state) => state.centered);
  const step = useAgeDetectionStore((state) => state.step);
  const age = useAgeDetectionStore((state) => state.age);
  const faceDetected = useAgeDetectionStore((state) => state.faceDetected);
  const faces = useAgeDetectionStore((state) => state.faces);
  const ageResult = useAgeDetectionStore((state) => state.ageResult);
  const loopTime = useAgeDetectionStore((state) => state.loopTime);
  const submittingLoading = useAgeDetectionStore((state) => state.submittingLoading);

  const progress = useProgressStore((state) => state.progress);

  const displayKeyFace = useConfigStore((state) => state.debug.displayKeyFace);
  const displayAgePredictionFace = useConfigStore((state) => state.debug.displayAgePredictionFace);
  const infiniteFaceCollection = useConfigStore((state) => state.debug.infiniteFaceCollection);
  const percentToComplete = useConfigStore((state) => state.humanConfig.livenessCheck.percentToComplete);
  const numAngles = useConfigStore((state) => state.humanConfig.livenessCheck.numAngles);
  const disableAgePrediction = useConfigStore((state) => state.humanConfig.ageDetection.disableAgePrediction);
  const serverModelConfig = useConfigStore((state) => state.humanConfig.serverModelConfig);
  const idCheckServer = serverModelConfig.idCheck;
  const liveCheckServer = serverModelConfig.livenessCheck;
  const ageThresholdServer = serverModelConfig.ageThreshold;

  const human = useHumanAgePredictor();

  useEffect(() => {
    // Handle case where user clicks the browser back button
    // - stop the video stream
    // - end the recursion
    const video = videoRef.current;
    const handlePopState = () => {
      human.breakRecursiveAgePrediction();
      if (!video) {
        return;
      }

      const mediaStream = video.srcObject as MediaStream;
      stopStream(mediaStream);
      video.srcObject = null;
    };
    window.onpopstate = handlePopState;
  }, []);

  const stopStreamAndNavigate = useCallback((nextLocation: string) => {
    try {
      const video = videoRef.current;
      if (video) {
        const mediaStream = video.srcObject as MediaStream;
        stopStream(mediaStream);
        video.srcObject = null;
      }
      navigate(nextLocation);
    } catch (err) {
      console.error('Error stopping stream and navigating', err);
      navigate(nextLocation);
    }
  }, [navigate]);

  useEffect(() => {
    const awaitSetVideoSource = async () => {
      if (!videoRef.current) {
        return;
      }
      await setVideoSource(videoRef.current, 'user', (err) => {
        if (err.name === 'NotAllowedError') {
          logger.warn('Camera permission denied.', { error: err.message });
          navigate('/camera-permission');
          return;
        }
        logger.warn(
          loggerMessages.agePrediction.warn.videoSource,
          {
            errorMessage: err.message,
            type: 'ERR_SET_VIDEO_SOURCE',
            aggregates: {
              errorCode: AgePredictionErrorCodes.ERR_SET_VIDEO_SOURCE,
              phaseType: 'age-prediction:error',
            },
          },
        );
        navigate(`/exit?error=1&errorCode=${AgePredictionErrorCodes.ERR_SET_VIDEO_SOURCE}`);
      });
      setLoading(false);
    };
    awaitSetVideoSource();
  }, [navigate]);

  useEffect(() => {
    if ((videoRef.current !== null) && (!videoElReady || status !== null)) {
      return;
    }

    // Reset state on mount just in case
    // the user navigates back to this page
    human.resetAgePrediction();
    const video = videoRef.current;
    setTimeout(() => {
      // Waiting 1 second before starting age prediction because
      // starting it immediately after the video element is ready
      // can cause the key face to be captured before the camera
      // has adjusted to the lighting resulting in a dark image
      human.recursiveAgePrediction(Date.now(), video as HTMLVideoElement);
    }, 1000);
  }, [videoElReady, human, status]);

  useEffect(() => {
    if (status === null) {
      return;
    }

    if (status === 'timedOut' && demoCount < 2 && !liveCheckServer) {
      setShowDemo(true);
      return;
    }

    if (status === 'livenessFail' && liveCheckServer) {
      mixpanel.trackAgePrediction({ event: 'Liveness Fail' });
      logger.info('User failed liveness check');
      stopStreamAndNavigate('/try-again');
      return;
    }

    // If we have no face readings we cannot face match with the ID.
    // Send to error page unless we are doing ID check on server side
    if (!faces.length && !idCheckServer) {
      mixpanel.trackAgePrediction({ event: 'Age Prediction No Face Readings', status });
      logger.warn(loggerMessages.agePrediction.warn.noFaceTimeout, {
        aggregates: {
          redirectTo: 'exit',
          status,
          phaseType: 'age-prediction:no-face-timeout',
        },
      });
      stopStreamAndNavigate(`/exit?error=1&errorCode=${AgePredictionErrorCodes.ERR_NO_FACE_TIMEOUT}`);
      return;
    }

    // If the user timed out but we have at least one face reading
    // Send to ID verification
    if (status === 'timedOut') {
      mixpanel.trackAgePrediction({ event: 'Age Prediction Timeout -> ID Upload' });
      const nextRoute = '/prepare-id-verification';
      logger.warn(loggerMessages.agePrediction.warn.noProgressTimeout, {
        aggregates: {
          status,
          phaseType: 'age-prediction:no-progress-timeout',
          nextRoute,
        },
      });
      stopStreamAndNavigate(nextRoute);
      return;
    }

    if (disableAgePrediction) {
      const nextRoute = '/prepare-id-verification';
      logger.info('Liveness check complete -> ID upload', {
        aggregates: {
          status,
          nextRoute,
        },
      });
      stopStreamAndNavigate(nextRoute);
      return;
    }

    // Threshold should always be defined but just in case fallback to 999 to for ID upload
    const threshold = idCheckServer
      ? ageThresholdServer
      : human.agePredictor?.model?.passingValue || 999;

    // If the user is estimated too young
    // Send to ID verification
    if (!age || (age < threshold)) {
      mixpanel.trackAgePrediction({ event: 'Age Predicted Below Threshold', predictedAge: age });
      const nextRoute = '/prepare-id-verification';
      logger.info(loggerMessages.agePrediction.info.predicted, {
        aggregates: {
          redirectTo: nextRoute,
          status,
          aboveThreshold: false,
          age: age ?? 0,
          threshold,
          phaseType: 'age-prediction:too-low',
        },
      });
      stopStreamAndNavigate(nextRoute);
      return;
    }

    // The user is estimated to be above the threshold
    // Send to success page
    mixpanel.trackAgePrediction({ event: 'Age Predicted Above Threshold', predictedAge: age });
    logger.info(loggerMessages.agePrediction.info.predicted, {
      aggregates: {
        redirectTo: 'exit',
        status,
        aboveThreshold: true,
        age,
        threshold,
        phaseType: 'age-prediction:success',
      },
    });

    // wait 500ms second for the circle to display fully green
    // before moving on to the next step
    stopStreamAndNavigate('/exit');
  }, [stopStreamAndNavigate, status, age, faces, setShowDemo, demoCount, human, disableAgePrediction,
    idCheckServer, liveCheckServer, ageThresholdServer]);

  useEffect(() => {
    if (!videoElReady || isServer) {
      return () => {};
    }
    // If no progress is made after 10 seconds, show the demo overlay
    // After the first time, show the overlay every 3 seconds if no progress is made
    const time = stallCount.current === 0 ? 10_000 : 3_000;

    const noProgressTimeout = setTimeout(() => {
      stallCount.current += 1;
      if (stallCount.current === 1) {
        mixpanel.trackEvent({ event: 'Stalled Progress' });
      }

      logger.info('Displaying demo overlay because no progress was made', {
        aggregates: {
          stallCount: stallCount.current,
        },
      });
      setDisplayDemo(true);
      setDemoPaused(false);
    }, time);

    if (progress) {
      setDisplayDemo(false);

      setDemoPaused(false);
    }

    return () => clearTimeout(noProgressTimeout);
  }, [progress, videoElReady, isServer]);

  const handleLoadedMetadata = useCallback(() => {
    setVideoElReady(true);
  }, [setVideoElReady]);

  /**
   * We must use the values from getBoundingClientRect()
   * for the arc SVGs to be positioned correctly.
   * I am not sure why but it works
   * Using the values from video width and height does not work
   */
  const videoRect = videoRef.current?.getBoundingClientRect();
  const w = videoRect ? videoRect.width : 0;
  const h = videoRect ? videoRect.height : 0;
  const cx = videoRect ? Math.ceil(w / 2) : 0;
  const cy = videoRect ? Math.ceil(h / 2) : 0;
  const r = cx > cy ? cy : cx;
  const completionCount = human.faceAngles.filter((a) => a.completed).length;
  const neededAngles = numAngles * percentToComplete;

  return (
    <>
      {submittingLoading && (
      <FullscreenLoader
        message={t('agePrediction.analyzing')}
        bgClass="bg-main"
      />
      )}
      <div className="relative flex-1">
        <div className="flex justify-center mt-4">
          <div className="relative">
            <video
              id={id}
              ref={videoRef}
              data-testid="age-prediction-video"
              style={{
                transform: 'rotateY(180deg)',
                visibility: videoElReady ? 'visible' : 'hidden',
              }}
              className="object-cover w-full h-full border-2 border-main"
              onLoadedMetadata={handleLoadedMetadata}
              playsInline
              autoPlay
              muted
            />

            <div
              className={clsx('absolute top-0 left-0 right-0 transition-opacity', {
                'opacity-0 duration-[500ms]': !displayDemo,
                'opacity-100 duration-1000': displayDemo,
              })}
              style={{
                height: h,
                width: w,
              }}
            >
              <Lottie
                options={{
                  animationData: liveCheck,
                  autoplay: true,
                  loop: true,
                  rendererSettings: {
                    className: 'transition-opacity duration-1000',
                  },
                }}
                width={215}
                style={{
                  opacity: 0.8,
                  height: h,
                  width: w,
                  backgroundColor: 'black',
                }}
                isPaused={demoPaused}
              />
            </div>

            <CircleMaskSVG
              width={w}
              height={h}
              radius={r}
            />
            {step === 2 && (
              <>
                {/* <EulerArcSVG
                euler="yaw"
                radius={r}
                cx={cx}
                cy={cy}
              /> */}
                {/* <EulerArcSVG
                euler="pitch"
                radius={r}
                cx={cx}
                cy={cy}
              /> */}
                {/* <RotationSVG
                width={w}
                height={h}
                radius={r}
              /> */}

                <CompleteCircleSVG
                  cx={cx}
                  cy={cy}
                  radius={r}
                  hidden={progress !== 100}
                />
                {progress !== 100 && (
                  human.faceAngles.map((a) => (
                    a.completed ? (
                      <ProgressArcSVG
                        key={a.angle}
                        cx={cx}
                        cy={cy}
                        radius={r}
                        startAngle={a.vector[0]}
                        length={a.vector[1]}
                      />
                    ) : null
                  ))
                )}
              </>
            )}

            {(!videoElReady || loading) && (
              <div className="flex justify-center items-center">
                <FullscreenLoader message={t('agePrediction.getReady')} />
              </div>
            )}
          </div>
        </div>

        {completionCount < neededAngles && videoElReady && (
          <FaceAngleMessage
            completionCount={completionCount}
            centered={centered}
            faceDetected={faceDetected}
            step={step}
          />
        )}

        {/* These conditionals below are only used for debugging */}
        {infiniteFaceCollection && (
          <>
            <Text className="text-white" id="age-debug-lively">
              ...
            </Text>
            <Text className="text-white" id="age-debug-human">
              ...
            </Text>
          </>
        )}
        {displayAgePredictionFace && (
          <>
            <div className="flex justify-center" id="agePredictionImage" />
            {ageResult && (
            <Text className="text-white">
              Age result:
              {' '}
              {ageResult}
            </Text>
            )}
            {loopTime && (
            <Text className="text-white">
              Loop time:
              {' '}
              {loopTime}
            </Text>
            )}
          </>
        )}
        {displayKeyFace && <div className="flex justify-center" id="keyFaceImage" />}
      </div>
    </>
  );
}

export default AgePrediction;
