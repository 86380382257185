import React from 'react';
import clsx from 'clsx';
import Loader from './Loader';
import Text from './Text';

interface Props {
  message?: string;
  bgClass?: string;
}

function FullscreenLoader({ message = '', bgClass = '' }: Props) {
  return (
    <div className={clsx('fixed flex flex-col items-center justify-center inset-0 z-10', { [bgClass]: !!bgClass })}>
      {!!message && (
        <div className="flex flex-col items-center justify-center inset-0">
          <Text size="lg" className="mb-4 text-center">{message}</Text>
        </div>
      )}
      <Loader />
    </div>
  );
}

export default FullscreenLoader;
