import React, { useEffect } from 'react';
import Exclamation from 'components/svgs/Exclamation';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import logger from 'services/logger';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import mixpanel from 'services/mixpanel';
import { useHumanAgePredictor } from 'contexts/HumanAgePredictionContext';

function TryAgain() {
  const { t } = useTranslation();
  const human = useHumanAgePredictor();

  useEffect(() => {
    human.resetAgePrediction();
  }, [human]);

  return (
    <div className="flex flex-1 flex-col items-center justify-between py-16 min-h-screen px-8 overflow-y-scroll">
      <div className="flex flex-1 flex-col items-center justify-start">
        <Exclamation />
        <h1 className="text-center text-lg font-bold tracking-tight mb-8">
          {t('tryAgain.title')}
        </h1>
        <Text className="text-center mb-8">
          {t('tryAgain.body')}
        </Text>
      </div>
      <Button
        id="try-again-link"
        component={Link}
        to="/verify-age"
        onClick={() => {
          mixpanel.trackEvent({ event: 'Try Again Click' });
          logger.info('User clicked try again');
        }}
      >
        {t('tryAgain.tryAgain')}
      </Button>
    </div>
  );
}

export default TryAgain;
