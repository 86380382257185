import React, { Dispatch, useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { baseClasses, buttonSizes, buttonVariants } from 'components/Button';
import FullscreenLoader from 'components/FullscreenLoader';
import axios from 'services/api/axios';
import logger from 'services/logger';
import useFetchConfig from 'services/useFetchConfig';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import useVerificationStore from 'stores/verificationStore';
import { MintErrorCodes, loggerMessages } from 'types/logger';
import { getMessageFromError } from 'utils/errorMessage';
import { useTranslation } from 'react-i18next';
import useConfigStore from 'stores/configStore';

interface SuccessProps {
  setMintStatus: Dispatch<React.SetStateAction<'loading' | 'success' | 'error'>>
  mintStatus: 'loading' | 'success' | 'error';
}

function Success({ setMintStatus, mintStatus }: SuccessProps) {
  const { emblemHost, loading } = useFetchConfig();
  const tagRequestId = useVerificationStore((s) => s.tagRequestId) || '';
  const isRetry = useAgeDetectionStore((s) => s.isRetry);
  const emblemState = useVerificationStore((s) => s.emblemState);
  const projectKey = useVerificationStore((s) => s.projectKey);
  const mobileDeepLink = useVerificationStore((s) => s.mobileDeepLink);
  const companyName = useVerificationStore((s) => s.companyName);
  const networkId = useVerificationStore((s) => s.networkId);
  const companySlug = useVerificationStore((s) => s.companySlug);
  const allowSkip = useConfigStore((s) => s.allowSkip);
  const { t, i18n } = useTranslation();
  const search = new URLSearchParams({
    emblemState,
    projectKey,
    tagRequestId,
    lng: i18n.language,
    companyName,
    reportUsage: 'true',
  });
  const accessUrl = mobileDeepLink ? `${mobileDeepLink}?${search.toString()}` : `${emblemHost}/access?${search.toString()}`;

  useEffect(() => {
    logger.info(loggerMessages.phases.info.pass, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
  }, []);

  useEffect(() => {
    if (allowSkip) {
      setMintStatus('success');
      return;
    }

    // The public config is still being fetched
    if (loading) {
      return;
    }

    if (!companySlug || !networkId || !tagRequestId) {
      setMintStatus('error');
      logger.error(loggerMessages.mintTag.error.missingParams, {
        companySlug,
        networkId,
        tagRequestId,
      });
      return;
    }

    const setTagRequest = async () => {
      const body = { status: 'approved' };
      try {
        logger.info(loggerMessages.mintTag.info.mintStart);
        await axios.post(
          `/api/safe-passage/v1/emblem-integration/trust-authority/companies/${companySlug}/requests/${tagRequestId}/mint`,
          body,
          {
            headers: {
              'Content-Type': 'application/json',
              'x-network-id': networkId,
            },
          },
        );
        logger.info(loggerMessages.mintTag.info.mintComplete);
        setMintStatus('success');
      } catch (err) {
        const errMsg = getMessageFromError(err);
        setMintStatus('error');
        console.error(err);
        logger.error(loggerMessages.mintTag.error.mintError, {
          type: 'ERR_MINT_ROUTE',
          errorMessage: errMsg,
          tagRequestId,
          aggregates: { errorCode: MintErrorCodes.ERR_MINT_ROUTE },
        });
      }
    };
    setTagRequest();
  }, [tagRequestId, networkId, companySlug, setMintStatus, allowSkip, loading]);

  if (mintStatus === 'loading') {
    return <FullscreenLoader />;
  }

  return (
    <div className="flex flex-col flex-1 items-center">
      <div className="flex flex-1 flex-col items-center justify-center mb-6">
        <h1 className="text-center text-lg tracking-tight font-bold mb-4">
          {t('success.title')}
        </h1>
        <CheckCircleIcon className={clsx('w-32 h-32 border rounded-full text-green-400')} />
      </div>

      <a
        className={clsx(baseClasses, buttonSizes.md, buttonVariants.primary, 'mb-4')}
        onClick={() => {
          logger.info(loggerMessages.successPage.info.continue);
          logger.flush();
        }}
        href={accessUrl}
        id="continue-to-site-link"
      >
        {t('success.continueToSite')}
      </a>
    </div>
  );
}

export default Success;
