import React from 'react';
import { Trans } from 'react-i18next';
import Text from './Text';

interface DetermineProps {
  completionCount: number;
  centered: boolean;
  faceDetected: boolean;
  step: number;
}

function DetermineFaceAngleMessage({
  completionCount,
  centered,
  faceDetected,
  step,
}: DetermineProps) {
  if (!faceDetected || !centered) {
    return (
      <Trans
        i18nKey="faceAngleMessage.moveFace"
        components={{
          br: <br />,
        }}
      />
    );
  }

  if (step === 1) {
    return (
      <Trans
        i18nKey="faceAngleMessage.lookStraight"
        components={{
          br: <br />,
        }}
      />
    );
  }

  if (completionCount === 0) {
    return (
      <Trans
        i18nKey="faceAngleMessage.turnHead"
        components={{
          br: <br />,
        }}
      />
    );
  }

  if (completionCount > 0) {
    return (
      <Trans
        i18nKey="faceAngleMessage.keepTurningClockwise"
        components={{
          br: <br />,
        }}
      />
    );
  }
}

interface Props {
  completionCount: number;
  centered: boolean;
  faceDetected: boolean;
  step: number;
}

function FaceAngleMessage({
  completionCount,
  centered,
  faceDetected,
  step,
}: Props) {
  return (
    <div className="w-full flex items-center justify-center text-center font-bold">
      <Text className="text-white" size="xl">
        <DetermineFaceAngleMessage
          completionCount={completionCount}
          centered={centered}
          faceDetected={faceDetected}
          step={step}
        />
      </Text>
    </div>
  );
}

export default FaceAngleMessage;
