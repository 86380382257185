import React, { useEffect, useState } from 'react';
import AgePrediction from 'components/AgePrediction';
import Demo from 'components/Demo';
import logger from 'services/logger';
import { loggerMessages } from 'types/logger';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import { useHumanAgePredictor } from 'contexts/HumanAgePredictionContext';
import useConfigStore from 'stores/configStore';

function VerifyAgeRoute() {
  const isRetry = useAgeDetectionStore((s) => s.isRetry);
  const disableAgePrediction = useConfigStore((state) => state.humanConfig.ageDetection.disableAgePrediction);
  const { agePrediction, livenessCheck } = useConfigStore((state) => state.humanConfig.serverModelConfig);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [showDemo, setShowDemo] = useState(true);
  const [demoCount, setDemoCount] = useState(0);

  const human = useHumanAgePredictor();
  const [agePredictionInitialized, setAgePredictionInitialized] = useState(human.agePredictorActive);
  const isServer = agePrediction && livenessCheck;

  useEffect(() => {
    if (disableAgePrediction) {
      // Still collect faces for ID face matching
      return () => {};
    }

    if (human.agePredictorActive) {
      return () => {};
    }
    /**
     * The only time agePredictorActive should be false is when the user
     * navigates back to this page after navigating away from it
     * NOTE: human.agePredictorActive is a constant value, no need for deps
     */

    setAgePredictionInitialized(false);
    human.initAgePredictWorker()
      .then(() => {
        logger.info('Age prediction worker initialized.');
        setAgePredictionInitialized(true);
      });

    return () => {
      human.terminateAgePredictWorker();
      logger.info('Age prediction worker terminated.');
    };
  }, [disableAgePrediction, human]);

  useEffect(() => {
    logger.info(loggerMessages.phases.info.start, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
  }, []);

  useEffect(() => {
    if (showDemo) {
      human.resetAgePrediction();
      setDemoCount((prev) => prev + 1);
    }
  }, [showDemo, human]);

  if (showDemo && demoCount <= 2 && !isServer) {
    return (
      <Demo
        animationComplete={animationComplete}
        setAnimationComplete={setAnimationComplete}
        setShowDemo={setShowDemo}
        allowSkip={agePredictionInitialized || disableAgePrediction}
      />
    );
  }

  return (
    <div className="flex flex-col w-full p-2 overflow-hidden">
      <AgePrediction
        setShowDemo={setShowDemo}
        demoCount={demoCount}
        isServer={isServer}
      />
    </div>
  );
}

export default VerifyAgeRoute;
