import React from 'react';

function Exclamation() {
  return (
    <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2358_4015)">
        <path d="M85.0051 24.4721C53.9508 24.4721 28.7763 49.6466 28.7763 80.7009C28.7763 111.755 53.9508 136.93 85.0051 136.93C116.059 136.93 141.234 111.755 141.234 80.7009C141.234 75.3152 140.477 70.1064 139.063 65.1751" stroke="#45E9FF" strokeWidth="2.34095" strokeLinecap="round" />
      </g>
      <circle cx="84.9503" cy="80.7003" r="36.4148" fill="#219BD3" stroke="white" strokeWidth="1.07102" />
      <path d="M85 67.3125V81.0216M85 94.7307H85.0343" stroke="white" strokeWidth="6.85456" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.0046 126.5C110.735 126.5 131.594 106.263 131.594 80.2364C131.594 54.2099 110.735 33.1113 85.0046 33.1113C59.2739 33.1113 38.415 54.2099 38.415 80.2364C38.415 85.1048 39.1449 89.8008 40.5 94.2186" stroke="#3A628E" strokeWidth="1.28021" strokeLinecap="round" />
      <foreignObject x="26.6336" y="22.3294" width="116.742" height="116.742">
        <div
          style={{
            backdropFilter: 'blur(0.54px)', clipPath: 'url(#bgblur_0_2358_4015_clip_path)', height: '100%', width: '100%',
          }}
        />
      </foreignObject>
      <path data-figma-bg-blur-radius="1.07102" fillRule="evenodd" clipRule="evenodd" d="M85.0044 23.4004C112.573 23.4004 135.594 42.8692 141.068 68.8078H28.9403C34.4152 42.8692 57.4362 23.4004 85.0044 23.4004Z" fill="#547DAA" fillOpacity="0.4" />
      <line y1="-0.535512" x2="166.009" y2="-0.535512" transform="matrix(1 0 0 -1 2 68.3828)" stroke="url(#paint0_linear_2358_4015)" strokeWidth="1.07102" />
      <defs>
        <filter id="filter0_d_2358_4015" x="0.830347" y="0.809269" width="168.35" height="168.35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4.2841" />
          <feGaussianBlur stdDeviation="13.3878" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.278431 0 0 0 0 0.529412 0 0 0 0 0.964706 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2358_4015" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2358_4015" result="shape" />
        </filter>
        <clipPath id="bgblur_0_2358_4015_clip_path" transform="translate(-26.6336 -22.3294)">
          <path fillRule="evenodd" clipRule="evenodd" d="M85.0044 23.4004C112.573 23.4004 135.594 42.8692 141.068 68.8078H28.9403C34.4152 42.8692 57.4362 23.4004 85.0044 23.4004Z" />
        </clipPath>
        <linearGradient id="paint0_linear_2358_4015" x1="0" y1="0.5" x2="166.009" y2="0.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#86DFFF" stopOpacity="0" />
          <stop offset="0.196169" stopColor="#93E8FF" />
          <stop offset="0.835231" stopColor="#93E8FF" />
          <stop offset="1" stopColor="#86DFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Exclamation;
