import { Tensor } from 'onnxruntime-web';

// Normalize image pixel data and arrange it in channel-first order.
export function normalizePixelData(data:Uint8ClampedArray, width:number, height:number, mean = 0.5, std = 0.25) {
  const numPixels = width * height;
  const floatData = new Float32Array(numPixels * 3);
  for (let i = 0; i < numPixels; i++) {
    const r = data[i * 4] / 255;
    const g = data[i * 4 + 1] / 255;
    const b = data[i * 4 + 2] / 255;
    floatData[i] = (r - mean) / std;
    floatData[i + numPixels] = (g - mean) / std;
    floatData[i + 2 * numPixels] = (b - mean) / std;
  }
  return floatData;
}

// Convert ImageData to an ONNX tensor.
export function imageDataToTensor(
  imageData: ImageData,
  width:number,
  height:number,
  tensorShape: [number, number, number, number],
  normalize: boolean,
): Tensor {
  if (normalize) {
    const normalizedData = normalizePixelData(imageData.data, width, height);
    return new Tensor('float32', normalizedData, tensorShape);
  }
  const d = imageData.data;

  const tensorData = new Float32Array(width * height * 3);

  // Fill tensorData by iterating over imageData
  for (let i = 0, j = 0; i < d.length; i += 4, j += 3) {
    tensorData[j] = d[i] / 255.0; // R
    tensorData[j + 1] = d[i + 1] / 255.0; // G
    tensorData[j + 2] = d[i + 2] / 255.0; // B
    // Ignoring alpha channel: data[i + 3]
  }

  const tensor = new Tensor('float32', tensorData, tensorShape);
  return tensor;
}

// A stable sigmoid function for post-processing.
export function stableSigmoid(x: number) {
  const clipped = Math.max(Math.min(x, 50), -50);
  return 1 / (1 + Math.exp(-clipped));
}

export default imageDataToTensor;
