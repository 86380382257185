/* eslint-disable max-len */
import { EventEmitter } from 'events';
import Human, { FaceResult, Box } from '@vladmandic/human';
import logger from 'services/logger';
import { getMessageFromError } from 'utils/errorMessage';
import {
  errorMessages, FaceMatchErrorCodes, loggerMessages, AgePredictionErrorCodes,
} from 'types/logger';
import { HumanConfigValues, DebugConfig, ModelConfig } from 'stores/configStore';
import detectCenteredFace from 'utils/detectCenteredFace';
import getErrorMessage from 'utils/getErrorMessage';
import getMedianValue from 'utils/getMedianValue';
import straightFaceMessage from 'utils/straightFaceMessage';
import validateFace from 'utils/validateFace';
import isMobileDevice from 'utils/isMobileDevice';
import createFaceAngles from 'utils/createFaceAngles';
import AgePredictor from 'services/onnx/AgePredictor';
import calculateFaceAngle from 'utils/calculateFaceAngle';
// eslint-disable-next-line import/no-webpack-loader-syntax
import HumanWorker from 'workerize-loader!./human.worker';
import PostAndReceiveWorker from 'services/PostAndReceiveWorker';
import mixpanel from 'services/mixpanel';
import isOffscreenCanvasSupported from 'utils/isOffscreenCanvasSupported';
import axios from '../api/axios';
import {
  FaceAngle, FaceResultMessage, HumanWorkerMessageData, SimilarityResultMessage,
} from './types';
import { config, faceDetectOnlyConfig } from './config';

class HumanAgePrediction extends EventEmitter {
  human: Human | null;
  humanWorker: PostAndReceiveWorker<HumanWorkerMessageData> | null;
  initialized: boolean;
  config: HumanConfigValues;
  debugConfig: DebugConfig;
  emblemState: string;
  videoRef: HTMLVideoElement | null = null;
  faceResults: FaceResult[];
  timeInProcess: number;
  _idleProgressTiming: number;
  _idleReinitTiming: number;
  timeInStep2: number;
  keyFace: FaceResult | null;
  keyFaceMismatches: number;
  maxPitch: number;
  minPitch: number;
  maxYaw: number;
  minYaw: number;
  _stepsLogged: number;
  realCheck: boolean;
  liveCheck: boolean;
  allAnglesComplete: boolean;
  startStep3: boolean;
  latestLiveScore: number | undefined;
  latestRealScore: number | undefined;
  _stalledProgressLogged: boolean;
  _timeoutId: ReturnType<typeof setTimeout> | null;
  timeoutImage: File | null;
  endRecursion: boolean;
  agePredictor: AgePredictor | null;
  ages: number[];
  faceAngles: FaceAngle[];
  videoFrameCanvas: OffscreenCanvas | HTMLCanvasElement | null = null;
  agePredictorActive: boolean;
  isOffscreenCanvasSupported: boolean;
  disableAgePrediction: boolean;
  useBackendAgePrediction: boolean;
  useBackendLivenessCheck: boolean;
  faceImages: Blob[];
  faceBoxImages: Blob[];
  livenessSubmitting: boolean;
  sessionId: string | null;
  modelLoadingProgress: {
    human: { total: number; loaded: number; };
    onnx: { total: number; loaded: number; };
  };

  constructor(
    configValues: HumanConfigValues,
    debugConfig: DebugConfig,
    models: ModelConfig[],
    emblemState: string,
  ) {
    super();
    this.human = null;
    this.humanWorker = new PostAndReceiveWorker<HumanWorkerMessageData>({ worker: HumanWorker as Worker, name: 'Human' });
    this.humanWorker.worker.onmessage = (event) => {
      if (event.data.type === 'error') {
        logger.error('Human worker error', { error: event.data.error });
        this.emit('error', event.data.error);
      }
    };

    this.initialized = false;
    this.config = configValues;
    this.debugConfig = debugConfig || null;
    this.emblemState = emblemState;

    this.faceResults = [];
    this.timeInProcess = 0;
    this._idleProgressTiming = 0;
    this._idleReinitTiming = 0;
    this.timeInStep2 = 0;
    this.keyFace = null;
    this.keyFaceMismatches = 0;
    this.maxPitch = 0;
    this.minPitch = 0;
    this.maxYaw = 0;
    this.minYaw = 0;
    this._stepsLogged = 0;
    this.realCheck = false;
    this.liveCheck = false;
    this.allAnglesComplete = false;
    this.startStep3 = false;
    this.latestLiveScore = undefined;
    this.latestRealScore = undefined;
    this._stalledProgressLogged = false;
    this._timeoutId = null;
    this.timeoutImage = null;
    this.endRecursion = false;
    this.agePredictor = configValues.ageDetection.disableAgePrediction ? null : new AgePredictor(models);
    this.ages = [];
    this.faceAngles = createFaceAngles(configValues.livenessCheck.numAngles);
    this.videoFrameCanvas = null;
    this.agePredictorActive = false;
    this.isOffscreenCanvasSupported = isOffscreenCanvasSupported();
    this.disableAgePrediction = configValues.ageDetection.disableAgePrediction;
    this.useBackendAgePrediction = configValues.ageDetection.useBackendAgePrediction || configValues.serverModelConfig.agePrediction;
    this.useBackendLivenessCheck = configValues.serverModelConfig.livenessCheck;
    this.faceImages = [];
    this.faceBoxImages = [];
    this.livenessSubmitting = false;
    this.sessionId = null;
    this.modelLoadingProgress = {
      human: { total: 0, loaded: 0 },
      onnx: { total: 0, loaded: 0 },
    };

    this.agePredictor?.on('progress', ({ total, loaded }) => {
      this.modelLoadingProgress.onnx = { total, loaded };
      this.emit('modelLoadProgress', this.modelLoadingProgress);
    });

    this.onWarmUpProgress = this.onWarmUpProgress.bind(this);
  }

  async init() {
    const start = Date.now();

    // using this interval to track roughly how long the user was waiting if they bounce
    const interval = setInterval(() => {
      mixpanel.register({ modelLoadTime: Date.now() - start });
    }, 1000);
    try {
      const promises = [];
      if (!this.disableAgePrediction) {
        // ACCS will not use the Onnx model. Age prediction will be done by the backend service
        promises.push(this.initOnnx());
      }
      promises.push(this.initHuman());
      await Promise.all(promises);
    } catch (error) {
      const msg = getMessageFromError(error);
      const stack = (error as Error).stack || '';
      logger.error('Error initializing models', {
        errorMessage: msg,
        stack,
      });
      this.emit('initError', error);
    } finally {
      clearInterval(interval);
      const end = Date.now();
      const time = end - start;
      logger.info('Model load time', { time });
      mixpanel.register({ modelLoadTime: time });
    }
  }

  async initHuman() {
    logger.info(loggerMessages.agePrediction.debug.humanInit);
    const timeout = setTimeout(() => {
      logger.warn('Human took longer than 10 seconds to initialize');
    }, 10_000);
    const start = Date.now();
    const conf = this.useBackendLivenessCheck ? faceDetectOnlyConfig : config;
    try {
      if (!this.isOffscreenCanvasSupported) {
        logger.info('Initializing Human without web worker');
        this.human = new Human(conf);
        this.monitorNonWebWorkerProgress();
        this.human.load();
        this.human.warmup();
        this.initialized = true;
        this.emit('initialized');
        return;
      }
      logger.info('Initializing - Human Warmup');
      const humanRes = await this.humanWorker?.postAndReceiveProgress({ type: 'warmup', config: conf }, this.onWarmUpProgress);

      if (!humanRes || humanRes.type === 'error') {
        logger.error('Error warming up human', { error: humanRes?.error });
        this.emit('initError', new Error('Error warming up human'));
        return;
      }

      const end = Date.now();
      logger.debug(loggerMessages.agePrediction.debug.humanInitialized, {
        aggregates: {
          humanLoadTime: end - start,
        },
      });
      mixpanel.trackEvent({ event: 'Human Model Loaded', humanLoadTime: end - start });
      clearTimeout(timeout);
      this.initialized = true;
      this.emit('initialized');
    } catch (error) {
      clearTimeout(timeout);
      console.error(error);
      const msg = getMessageFromError(error);
      const stack = (error as Error).stack || '';
      logger.warn(loggerMessages.agePrediction.error.humanInit, {
        errorMessage: msg,
        stack,
        aggregates: { errorCode: AgePredictionErrorCodes.ERR_HUMAN_INIT },
      });
      this.emit('initError', error);
    }
  }

  async initOnnx() {
    if (!this.agePredictor) {
      return;
    }
    try {
      logger.info('Initializing - Onnx Model');
      const start = Date.now();
      const onnxRes = await this.agePredictor.init();

      if (onnxRes && ((onnxRes.type && onnxRes.type) === 'error' || onnxRes.error)) {
        logger.error('Error initializing onnx model', { error: onnxRes.error });
        this.emit('initError', new Error('Error initializing onnx model'));
        return;
      }

      this.agePredictorActive = true;

      logger.debug('Onnx Model initialized');
      const end = Date.now();
      mixpanel.trackEvent({ event: 'Onnx Model Loaded', onnxLoadTime: end - start });
      this.emit('onnxInitialized');
    } catch (error) {
      console.error('Error initializing ONNX', error);
      const msg = getMessageFromError(error);
      const stack = (error as Error).stack || '';
      logger.warn('Onnx error', {
        errorMessage: msg,
        stack,
      });
      this.disableAgePrediction = true;
      this.emit('initOnnxError', error);
    }
  }

  onWarmUpProgress({ total, loaded }: { total: number, loaded: number }) {
    this.modelLoadingProgress.human = { total, loaded };
    this.emit('modelLoadProgress', this.modelLoadingProgress);
  }

  monitorNonWebWorkerProgress() {
    if (!this.human) {
      return;
    }
    const progress = this.human.models.stats().percentageLoaded || 0;
    const stats = this.human.models.stats();
    if (!stats) return;

    const { totalSizeLoading, percentageLoaded } = stats;
    this.modelLoadingProgress.human = {
      total: totalSizeLoading,
      loaded: totalSizeLoading * percentageLoaded,
    };

    if (progress < 1) setTimeout(this.monitorNonWebWorkerProgress, 10);
  }

  async terminateHumanWorker() {
    logger.info('Terminating Human worker');
    try {
      if (!this.isOffscreenCanvasSupported) {
        // If offscreen canvas is not supported, Human was not initialized with a worker
        return;
      }
      const res = await this.postAndReceiveHumanMessage({ type: 'terminate' });
      if (res && res.type === 'dispose' && res.disposed) {
        logger.info('Human worker terminated');
        this.humanWorker?.worker.terminate();
        this.humanWorker = null;
      }
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error terminating Human worker', {
        errorMessage: msg,
      });
    }
  }

  async initHumanWorker() {
    logger.info('Initializing Human worker');
    this.humanWorker = new PostAndReceiveWorker<HumanWorkerMessageData>({ worker: HumanWorker as Worker, name: 'Human' });
    try {
      const conf = this.useBackendLivenessCheck ? faceDetectOnlyConfig : config;
      await this.humanWorker.postAndReceiveMessage({ type: 'warmup', config: conf });
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error initializing Human worker', {
        errorMessage: msg,
      });
      this.emit('initError', error);
    }
  }

  async postAndReceiveHumanMessage(data: HumanWorkerMessageData, transfer?: Transferable[]) {
    if (!this.humanWorker) {
      await this.initHumanWorker();
    }
    return (this.humanWorker as PostAndReceiveWorker<HumanWorkerMessageData>).postAndReceiveMessage(data, transfer);
  }

  terminateAgePredictWorker() {
    if (!this.agePredictor) {
      return;
    }

    try {
      if (!this.isOffscreenCanvasSupported) {
        // If offscreen canvas is not supported, onnx was not initialized with a worker
        // this.agePredictorActive should remain true so the we will not try to reinitialize
        return;
      }
      this.agePredictor.terminate();
      this.agePredictorActive = false;
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error terminating Onnx worker', {
        errorMessage: msg,
      });
    }
  }

  async initAgePredictWorker() {
    if (!this.agePredictor) {
      return;
    }
    try {
      await this.agePredictor.freshInit();
      this.agePredictorActive = true;
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error initializaing Onnx worker', {
        errorMessage: msg,
      });
      this.emit('initError', error);
    }
  }

  async idleReinit() {
    logger.warn(loggerMessages.agePrediction.warn.reinitializingHuman, { aggregates: { timeInProcess: this.timeInProcess } });
    try {
      await this.humanWorker?.postAndReceiveMessage({ type: 're-init' });

      this._idleReinitTiming = 0;
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error re-initializing Human', {
        errorMessage: msg,
        aggregates: { errorCode: AgePredictionErrorCodes.ERR_HUMAN_INIT },
      });
      this.emit('initError', error);
    }
  }

  async detectFace(img: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, willReadFrequently = false) {
    if (!this.initialized) {
      logger.warn('Human not initialized for face detection');
      return false;
    }

    let width = 0;
    let height = 0;
    let imageData: ImageData | null = null;
    if (img instanceof HTMLImageElement || img instanceof HTMLCanvasElement) {
      ({ width, height } = img);
    } else if (img instanceof HTMLVideoElement) {
      width = img.videoWidth;
      height = img.videoHeight;
    }

    // Create or reuse the OffscreenCanvas
    if (!this.videoFrameCanvas || this.videoFrameCanvas.width !== width || this.videoFrameCanvas.height !== height) {
      if (this.videoFrameCanvas) this.videoFrameCanvas = null;
      if (this.isOffscreenCanvasSupported) {
        this.videoFrameCanvas = new OffscreenCanvas(width, height);
      } else {
        this.videoFrameCanvas = document.createElement('canvas');
        this.videoFrameCanvas.width = width;
        this.videoFrameCanvas.height = height;
      }
    }

    const ctx = this.videoFrameCanvas.getContext('2d', { willReadFrequently }) as OffscreenCanvasRenderingContext2D;
    ctx.drawImage(img, 0, 0, width, height);
    imageData = ctx.getImageData(0, 0, width, height);
    if (!imageData) {
      return false;
    }

    if (!this.isOffscreenCanvasSupported) {
      const res = await this.human?.detect(imageData);
      return res?.face[0] || false;
    }

    const res = await this.postAndReceiveHumanMessage({
      type: 'detect',
      frame: imageData.data.buffer as ArrayBuffer,
      width,
      height,
    }, [imageData.data.buffer]) as FaceResultMessage;

    if (res.error) {
      logger.warn(
        loggerMessages.agePrediction.warn.humanJsDetect,
        {
          errorMessage: res.error,
          type: 'ERR_HUMAN_DETECT',
          aggregates: {
            errorCode: AgePredictionErrorCodes.ERR_HUMAN_DETECT,
            phaseType: 'age-prediction:error',
          },
        },
      );
      this.emit('error', res.error);
      throw new Error(res.error);
    }

    if (!res.face) {
      logger.debug(loggerMessages.agePrediction.info.noFaceDetected, {
        aggregates: { timeInProcess: this.timeInProcess },
      });
      return false;
    }

    return res.face;
  }

  async matchFaces(face: FaceResult, faces: FaceResult[]) {
    const { threshold } = this.config.faceMatch;
    if (!this.initialized) {
      logger.warn('Human not initialized for face matching');
      return { pass: false, similarity: 0 };
    }
    try {
      if (!face.embedding) {
        console.warn('Cannot match because no face embedding');
        return { pass: false, similarity: 0 };
      }
      const { embedding } = face;
      const { results } = await this.postAndReceiveHumanMessage({ type: 'matchFaces', embedding, faces }) as { results: [FaceResult, number][] };
      const resultsMap: Map<FaceResult, number> = new Map(results);
      // sort results map by similarity
      const sortedResults = [...resultsMap.entries()].sort((a, b) => b[1] - a[1]);
      const topSimilarity = sortedResults[0][1];
      const topFace = sortedResults[0][0];
      const pass = topSimilarity > threshold;

      if (this.debugConfig.displayTopSimilarityFace && topFace.embedding && face.box && this.videoFrameCanvas) {
        const [x, y, width, height] = face.box;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas?.getContext('2d');
        ctx?.drawImage(this.videoFrameCanvas, x, y, width, height);
        const div = document.getElementById('verify-id-image-container');
        div?.appendChild(canvas);
      }

      this.emit('faceMatchResult', {
        pass,
        similarity: topSimilarity,
        topFace,
      });
      mixpanel.trackFaceMatch({ pass, similarity: topSimilarity });

      if (pass) {
        logger.info(loggerMessages.verifyId.info.faceMatches, {
          aggregates: {
            resultsLength: sortedResults.length,
            pass,
            similarity: topSimilarity,
            threshold,
            phaseType: 'verify-id:success',
            age: topFace.age || 0,
            gender: topFace.gender || '',
            genderScore: topFace.genderScore || 0,
          },
        });
      } else {
        logger.warn(loggerMessages.verifyId.warn.faceDoesNotMatch, {
          type: 'ERR_FACE_MATCH_ID',
          errorMessage: errorMessages[FaceMatchErrorCodes.ERR_FACE_MATCH_ID],
          aggregates: {
            threshold,
            resultsLength: sortedResults.length,
            pass,
            similarity: topSimilarity,
            errorCode: FaceMatchErrorCodes.ERR_FACE_MATCH_ID,
            phaseType: 'verify-id:error',
            age: topFace.age || 0,
            gender: topFace.gender || '',
            genderScore: topFace.genderScore || 0,
          },
        });
      }

      return { pass, similarity: topSimilarity };
    } catch (error) {
      logger.warn(loggerMessages.verifyId.warn.matchingFaces, {
        errorMessage: getMessageFromError(error),
        type: 'ERR_MATCHING_FACES',
        aggregates: {
          errorCode: FaceMatchErrorCodes.ERR_MATCHING_FACES,
        },
      });
      return { pass: false, similarity: 0 };
    }
  }

  logStepChange(step1Complete: boolean, step2Complete: boolean) {
    if (step1Complete && this._stepsLogged === 0) {
      logger.info(loggerMessages.agePrediction.info.step, { aggregates: { stepCompleted: 1, timeInProcess: this.timeInProcess } });
      this._stepsLogged = 1;
    }

    if (step2Complete && this._stepsLogged === 1) {
      logger.info(loggerMessages.agePrediction.info.step, { aggregates: { stepCompleted: 2, timeInProcess: this.timeInProcess } });
      logger.info(
        loggerMessages.agePrediction.info.realAndLiveCheck,
        { aggregates: { liveScore: this.latestLiveScore ?? 0, realScore: this.latestRealScore ?? 0 } },
      );
      this.emit('realScore', this.latestRealScore ?? 0);
      this.emit('liveScore', this.latestLiveScore ?? 0);
      this._stepsLogged = 2;
    }
  }

  async step1SetKeyFace(face: FaceResult) {
    if (!face.embedding) {
      logger.warn('Cannot set key face because no face embedding');
      return;
    }

    if (!this.initialized) {
      logger.warn('Human not initialized for setting key face');
      return;
    }

    if (this.debugConfig.displayKeyFace && this.videoRef && this.videoFrameCanvas && face.box) {
      logger.info('Drawing key face to canvas');
      const keyFaceDiv = document.getElementById('keyFaceImage') as HTMLCanvasElement;

      const blob = await (this.videoFrameCanvas as OffscreenCanvas).convertToBlob();
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        keyFaceDiv.childNodes.forEach((child) => keyFaceDiv.removeChild(child));
        keyFaceDiv.appendChild(canvas);
      };

      img.src = URL.createObjectURL(blob);
    }

    logger.info('Setting key face', {
      aggregates: {
        score: face.score,
        faceScore: face.faceScore,
        boxScore: face.boxScore,
        age: face.age || 0,
        gender: face.gender || '',
        genderScore: face.genderScore || 0,
        real: face.real || 0,
        live: face.live || 0,
      },
    });
    mixpanel.trackAgePrediction({ event: 'Setting Key Face', keyFace: true });
    this.keyFace = face;
    this.emit('step', 2);
  }

  async step2CollectFaceAngles(face: FaceResult, pitch: number, yaw: number) {
    let progressMade = false;
    let ageResult = null;

    /**
     * If the user is on a device that does not support onnx for age prediction
     * we might have to skip this part.
     * In this case we will collect face readings for the liveness check and for
     * comparison with their photo ID.
     */
    if (!this.disableAgePrediction) {
      if (!face.box || !this.videoFrameCanvas) {
        logger.warn('No face box or video frame canvas', { faceBox: face.box, videoFrameCanvas: !!this.videoFrameCanvas });
        return progressMade;
      }
      const [x, y, width, height] = face.box;

      let faceBoxCanvas: OffscreenCanvas | HTMLCanvasElement;
      if (this.isOffscreenCanvasSupported) {
        logger.debug('Creating OffscreenCanvas');
        faceBoxCanvas = new OffscreenCanvas(width, height);
      } else {
        logger.debug('Creating HTMLCanvasElement');
        faceBoxCanvas = document.createElement('canvas');
        faceBoxCanvas.width = width;
        faceBoxCanvas.height = height;
      }
      const ctx = faceBoxCanvas.getContext('2d') as OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
      ctx.drawImage(this.videoFrameCanvas, x, y, width, height, 0, 0, width, height);

      if (this.debugConfig.displayAgePredictionFace) {
        const faceDiv = document.getElementById('agePredictionImage') as HTMLCanvasElement;
        const imageData = ctx.getImageData(0, 0, width, height);
        const canvas = document.createElement('canvas');
        canvas.width = faceBoxCanvas.width;
        canvas.height = faceBoxCanvas.height;
        if (imageData) {
          canvas.getContext('2d')?.putImageData(imageData, 0, 0);
        }

        // Get data URL and create image
        const dataUrl = canvas.toDataURL('image/png');
        const img = document.createElement('img');
        img.src = dataUrl;
        if (faceDiv.firstChild) faceDiv.removeChild(faceDiv.firstChild);
        faceDiv.appendChild(img);
      }

      if (this.agePredictor) {
        if (!this.isOffscreenCanvasSupported) {
          ageResult = await this.agePredictor.predictAgeWithoutWebWorker(faceBoxCanvas);
        } else {
          ageResult = await this.agePredictor.predictAge(faceBoxCanvas);
        }

        this.emit('ageResult', ageResult);

        if (ageResult === null || ageResult === 0) {
          logger.warn('No age result', { ageResult });
          return progressMade;
        }
        if (this.debugConfig.infiniteFaceCollection) {
          const pass = this.agePredictor.model?.passingValue || 0.6;
          const pLively = document.getElementById('age-debug-lively') as HTMLParagraphElement;
          pLively.innerHTML = `Lively Age: ${ageResult}`;
          pLively.style.color = ageResult > pass ? 'green' : 'yellow';
          const pHuman = document.getElementById('age-debug-human') as HTMLParagraphElement;
          pHuman.innerHTML = `Human Age: ${face.age}`;
          return progressMade;
        }
      }
    }

    const { maxFaces } = this.config.ageDetection;
    const {
      numAngles, proximity, percentToComplete, minPitchValue, minYawValue,
    } = this.config.livenessCheck;

    if (this.faceResults.length < maxFaces) {
      this.faceResults.push(face);
      if (ageResult !== null) this.ages.push(ageResult);
      progressMade = true;
    }

    /**
     * Because of the conversion made in calculateFaceAngle()
     * the face angle is off by 90 degrees. We adjust for
     * this by adding 90 to the angle if it is less than 270
     * and subtracting 270 if it is greater than 270
     */
    let angle = calculateFaceAngle(yaw, pitch);
    angle = angle < 270 ? angle + 90 : angle - 270;
    let twoOppositesComplete = false;
    const half = numAngles / 2;
    this.faceAngles.forEach((faceAngle, index) => {
      const withinRange = Math.abs(faceAngle.angle - angle) < proximity; // within 10 degrees
      const minFaceTurn = Math.abs(pitch) > minPitchValue || Math.abs(yaw) > minYawValue;
      if (withinRange && minFaceTurn && !faceAngle.completed) {
        progressMade = true;
        faceAngle.completed = true;
        // If this values opposite is also completed
        // then we have two angles 180 degrees apart
        const oppositeIndex = index < half
          ? index + half
          : index - half;
        twoOppositesComplete = this.faceAngles[oppositeIndex].completed;
      }
    });

    const progress = this.step2FaceAngleProgress();

    // Emit progress to trigger UI updates
    this.emit('progress', progress * 100);

    if (progress >= percentToComplete || twoOppositesComplete) {
      /**
       * We do not ask the user to turn their face 360 degrees.
       * Instead we will pass them on two conditions:
       * 1. If 50% or more of the face angles are completed
       * 2. If two angles exactly 180 degrees apart are completed
       *
       * If either condition is met, change all angles to complete
       */
      this.faceAngles.forEach((faceAngle) => {
        faceAngle.completed = true;
      });

      this.liveCheck = true;
      this.realCheck = true;
      this.emit('progress', 100);
    }

    return progressMade;
  }

  step2FaceAngleProgress() {
    const progress = this.faceAngles.reduce((acc, cur) => (cur.completed ? acc + 1 : acc), 0);
    const p = (progress / this.faceAngles.length);
    return p;
  }

  isCenteredAndStraight(face: FaceResult) {
    if (this.videoRef === null) {
      return {
        isCentered: false, isStraight: false, pitch: 0, yaw: 0,
      };
    }
    const { videoWidth, videoHeight } = this.videoRef;
    // const { width, height } = this.videoRef;
    const isCentered = detectCenteredFace(face.box, videoWidth, videoHeight);
    this.emit('centered', isCentered);

    const pitch = face?.rotation?.angle?.pitch || 0;
    const yaw = face?.rotation?.angle?.yaw || 0;
    // const roll = face?.rotation?.angle?.roll || 0;
    const { isStraight } = straightFaceMessage(pitch, yaw);
    this.emit('straight', isStraight);
    this.emit('angles', { pitch, yaw });

    if (this.debugConfig.logFaceAngles) {
      logger.debug('Face angles', { pitch, yaw });
    }

    return {
      isCentered, isStraight, pitch, yaw,
    };
  }

  async faceMatchesKeyFace(face: FaceResult) {
    if (!this.initialized) {
      logger.warn('Human not initialized for face matching');
      return false;
    }

    if (!this.keyFace || !this.keyFace.embedding) {
      logger.warn('Cannot match because no key face');
      this.keyFace = null;
      return false;
    }

    if (!face.embedding) {
      logger.warn('Cannot match because no face embedding');
      return false;
    }

    const { keyFaceMismatchLimit, keyFaceThreshold } = this.config.faceMatch;
    let similarity = 0;
    if (!this.isOffscreenCanvasSupported) {
      similarity = this.human?.match.similarity(face.embedding, this.keyFace.embedding) || 0;
    } else {
      // Compare each new face with the straight face from step 1
      ({ similarity } = await this.postAndReceiveHumanMessage({
        type: 'similarity',
        face1: face.embedding,
        face2: this.keyFace.embedding,
      }) as SimilarityResultMessage);
    }

    const match = similarity >= keyFaceThreshold;
    if (this.debugConfig.logFaceMatch) {
      logger.debug('Face match', { similarity, match });
    }

    if (!match) {
      logger.debug('Face does not match key face', { aggregates: { similarity } });
      this.keyFaceMismatches += 1;
      if (this.keyFaceMismatches < keyFaceMismatchLimit) {
        return match;
      }
      /**
       * We have reached the limit of consecutive face mismatches
       * This means the either
       * - the user is not the same person
       * - the key face was not a good representation of the user
       *
       * Restart the process with a new key face
       */
      logger.warn(loggerMessages.agePrediction.warn.faceMismatch, {
        errorMessage: errorMessages[AgePredictionErrorCodes.ERR_FACE_MISMATCH],
        type: 'ERR_FACE_MISMATCH',
        aggregates: {
          errorCode: AgePredictionErrorCodes.ERR_FACE_MISMATCH,
          timeInProcess: this.timeInProcess,
          numberOfFaces: this.faceResults.length,
          similarity,
        },
      });
      this.resetAgePrediction(false);
      return match;
    }

    this.keyFaceMismatches = 0;
    return match;
  }

  logIdleWarning(step1Complete: boolean, step2Complete: boolean) {
    logger.info(loggerMessages.agePrediction.info.stalledProgress, {
      aggregates: {
        timeInProcess: this.timeInProcess,
        stepCompleted: this._stepsLogged,
        step1Complete,
        step2Complete,
      },
    });
    this._stalledProgressLogged = true;
  }

  addIdleTime(now: number, then: number, reason: string) {
    logger.debug('Adding idle time', {
      idleTime: this._idleProgressTiming,
      reason,
    });
    this._idleProgressTiming += now - then;
  }

  addTimeInProcess(now: number, then: number) {
    this.timeInProcess += now - then;
  }

  addIdleReinitTime(now: number, then: number) {
    this._idleReinitTiming += now - then;
  }

  handleIdleTimeout() {
    logger.info(loggerMessages.agePrediction.info.timedOut, {
      aggregates: {
        stepCompleted: this._stepsLogged,
        timeInProcess: this.timeInProcess,
        idleProgressTiming: this._idleProgressTiming,
      },
    });
    mixpanel.trackAgePrediction({
      event: 'Age Prediction Timeout',
      timedOut: true,
      timeInProcess: this.timeInProcess,
      numberOfFaces: this.faceResults.length,
    });
    this.emit('faces', this.faceResults);
    this.emit('status', 'timedOut');
  }

  async backendAgePrediction(now: number, then: number) {
    if (!this.videoRef) {
      return false;
    }

    if (!this.liveCheck && !this.realCheck && this.faceImages.length > 0 && !this.livenessSubmitting) {
      this.emit('submittingLoading', true);
      this.livenessSubmitting = true;
      const live = await this.submitFaceImagesLiveness();
      this.realCheck = live;
      this.liveCheck = live;
      this.livenessSubmitting = false;
      if (!live) {
        this.emit('submittingLoading', false);
        this.resetAgePrediction(false);
        this.emit('status', 'livenessFail');
        return false;
      }
    }

    if (this.realCheck && this.liveCheck) {
      if (this.useBackendAgePrediction && !this.disableAgePrediction) {
        await this.submitFaceImagesAge();
      }
      this.completeAgePrediction();
      return false;
    }

    const face = await this.detectFace(this.videoRef, true);
    this.addTimeInProcess(now, then);

    if (!face) {
      this.emit('faceDetected', false);
      // this.addIdleReinitTime(now, then);
      this.addIdleTime(now, then, 'No face detected');
      return true;
    }

    await this.saveFaceImage(face.box);

    this.emit('faceDetected', true);
    this.emit('centered', true);
    return true;
  }

  completeAgePrediction() {
    if (this._timeoutId) clearTimeout(this._timeoutId);
    logger.debug('Age results', { ages: this.ages });
    const sorted = [...this.ages].sort();

    logger.debug('Sorted lowest to highest', { ages: sorted });
    const medianAge = getMedianValue(sorted);

    logger.info(loggerMessages.agePrediction.info.complete, {
      aggregates: {
        numberOfFaces: this.faceResults.length,
        age: medianAge,
        timeInProcess: this.timeInProcess,
        idleTime: this._idleProgressTiming,
        liveScore: this.latestLiveScore || 0,
        realScore: this.latestRealScore || 0,
      },
    });

    mixpanel.trackAgePrediction({
      event: 'Age Prediction Complete',
      completed: true,
      predictedAge: medianAge,
      numberOfFaces: this.faceResults.length,
      timeInProcess: this.timeInProcess,
      idleTime: this._idleProgressTiming,
    });

    this.emit('age', medianAge);
    this.emit('faces', this.faceResults);

    this.timeInProcess = 0;

    logger.flush();
    setTimeout(() => {
      // wait 500ms for green circle to complete
      this.emit('status', 'complete');
    }, 500);
  }

  async recursiveAgePrediction(then: number, videoRef?: HTMLVideoElement) {
    logger.flush();
    const now = Date.now();
    const loopTime = now - then;
    this.emit('loopTime', loopTime);
    logger.debug('Time between readings', {
      time: loopTime,
      aggregates: {
        timeBetweenReadings: now - then,
      },
    });
    const firstLoop = !!videoRef;
    if (firstLoop) {
      /** recursiveAgePrediction is called explicity from the component
       * with the videoRef as an argument. This means we know we want
       * to start the process and we don't want to end it early.
       *
       * All subsequent calls will not have the videoRef argument and should
       * defer to endRecursion to determine if the process should end.
       */
      mixpanel.trackAgePrediction({ event: 'Age Prediction Start', startTime: new Date().toISOString() });
      this.endRecursion = false;
    }

    if (this.endRecursion) {
      logger.debug('Age prediction recursion ended');
      return;
    }

    const { delay, idleTimeout } = this.config.ageDetection;

    // Ensure onnx age predictor worker is initialized
    if (!this.agePredictorActive && !this.disableAgePrediction) {
      logger.debug('Waiting on age predictor to initialize');
      this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
      return;
    }

    if (videoRef) {
      this.videoRef = videoRef;
    }

    if (!this.initialized) {
      logger.warn('Human not initialized for age prediction');
      return;
    }

    if (this.videoRef === null) {
      logger.warn('Video ref not set for age prediction');
      return;
    }

    /**
     * The user has progressed for the idle timeout duration
     */
    if (this._idleProgressTiming > idleTimeout) {
      this.handleIdleTimeout();
      return;
    }

    if (this.useBackendAgePrediction) {
      const proceed = await this.backendAgePrediction(now, then);
      if (!proceed) {
        return;
      }
      this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
      return;
    }

    const step1Complete = this.keyFace !== null;
    const step2FaceAnglesComplete = this.step2FaceAngleProgress() === 1;
    const step2Complete = step1Complete && step2FaceAnglesComplete;

    this.logStepChange(step1Complete, step2Complete);

    /**
     * The user has progressed for 3 seconds
     * Log that the user has stalled
     */
    if (this._idleProgressTiming > 3_000 && !this._stalledProgressLogged) {
      this.logIdleWarning(step1Complete, step2Complete);
    }

    /**
     * Successfully completed the age detection process and liveness check
     */
    if (step2Complete && this.realCheck && this.liveCheck) {
      this.completeAgePrediction();
      return;
    }

    try {
      const face = await this.detectFace(this.videoRef, true);
      this.addTimeInProcess(now, then);

      if (!face) {
        this.emit('faceDetected', false);
        // this.addIdleReinitTime(now, then);
        this.addIdleTime(now, then, 'No face detected');
        this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
        return;
      }

      const validation = validateFace(face, this.config.faceValidation);

      if (this.debugConfig.logValidFace) {
        logger.debug('Face validation', validation);
      }

      this.latestLiveScore = face.live;
      this.latestRealScore = face.real;

      if (this.debugConfig.logFaceResult) {
        logger.debug('Face result', { face: JSON.stringify(face) });
      }

      // Skip this face
      if (validation.isValidFace === false) {
        this.emit('faceDetected', false);
        this.addIdleTime(now, then, 'Invalid face');

        if (this.debugConfig.logInvalidFace) {
          logger.debug(loggerMessages.agePrediction.debug.faceFailedValidation, {
            aggregates: {
              ...validation,
              timeInProcess: this.timeInProcess,
            },
          });

          if (!validation.faceScoreValid) {
            logger.debug(loggerMessages.agePrediction.debug.faceScoreLow, {
              aggregates: { faceScore: validation.faceScore },
            });
          }

          if (!validation.hasEmbedding) {
            logger.debug(loggerMessages.agePrediction.debug.faceHasNoEmbedding);
          }
        }

        this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
        return;
      }

      // After step 1, we need to make sure the face matches the key face
      if (step1Complete) {
        const match = this.faceMatchesKeyFace(face);
        if (!match) {
          this.emit('faceDetected', false);
          this.addIdleTime(now, then, 'Face does not match key face');
          this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
          return;
        }
      }

      this.emit('faceDetected', true);

      const {
        isCentered, isStraight, pitch, yaw,
      } = this.isCenteredAndStraight(face);

      /**
       * Step 1
       * We need to get a baseline reading of the user's face looking
       * straight at the camera. This will be used to compare all other readings
       * against to make sure it is the same user throughout the process
       */
      if (!this.keyFace) {
        if (isCentered && isStraight) {
          await this.step1SetKeyFace(face);
        } else {
          logger.debug('Step 1 failed progress', {
            isCentered,
            isStraight,
          });
          this.addIdleTime(now, then, 'Face not centered or straight');
        }
      }

      /**
       * Step 2
       * Now that we have a baseline face reading have the user look straight
       * at the camera and start taking age measurements.
       */
      if (step1Complete && !step2Complete) {
        const progressMade = await this.step2CollectFaceAngles(face, pitch, yaw);
        if (!progressMade) {
          this.addIdleTime(now, then, 'No angle progress');
        }
      }

      // Take another face reading
      this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
    } catch (error) {
      if (this._timeoutId) clearTimeout(this._timeoutId);
      const msg = getErrorMessage(error as unknown as Error);

      // If we have at least one face, we can continue to verify ID with face match
      // Otherwise, we need to start over
      const nextLocation = this.faceResults.length > 0
        ? (isMobileDevice() ? '/verify-id-info' : '/verify-id?status=error')
        : `/exit?error=1&errorCode=${AgePredictionErrorCodes.ERR_RECURSIVE_AGE_PREDICTION}`;

      this.emit('faces', this.faceResults);

      logger.warn(
        loggerMessages.agePrediction.warn.detectingFace,
        {
          errorMessage: msg,
          type: 'ERR_RECURSIVE_AGE_PREDICTION',
          nextLocation,
          aggregates: {
            errorCode: AgePredictionErrorCodes.ERR_RECURSIVE_AGE_PREDICTION,
            phaseType: 'age-prediction:error',
          },
        },
      );

      this.emit('agePredictionError', {
        error: msg,
        nextLocation,
      });
    }
  }

  resetAgePrediction(includeTimeout = true) {
    if (includeTimeout) {
      this.timeInProcess = 0;
      this._idleProgressTiming = 0;
      this._timeoutId = null;
      this._stalledProgressLogged = false;
    }

    this.timeInStep2 = 0;
    this.faceResults = [];
    this.keyFace = null;
    this.keyFaceMismatches = 0;
    this.maxPitch = 0;
    this.minPitch = 0;
    this.maxYaw = 0;
    this.minYaw = 0;
    this._stepsLogged = 0;
    this.realCheck = false;
    this.liveCheck = false;
    this.allAnglesComplete = false;
    this.startStep3 = false;
    this.latestLiveScore = undefined;
    this.latestRealScore = undefined;
    this.endRecursion = false;
    this.ages = [];
    this.faceAngles = createFaceAngles(this.config.livenessCheck.numAngles);
    this.faceImages = [];
    this.livenessSubmitting = false;
    this.emit('reset');
  }

  async resizePhotoForDetection(fileUrl: string) {
    let face: false | FaceResult = false;
    let attempt = 1;
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src = fileUrl;
    const { width, height } = image;
    let newW = width;
    let newH = height;

    /**
     * Human has a hard time detecting faces images with large dimensions
     * Shrink the image so the largest size is 1000px
     */
    if (width > 1000 || height > 1000) {
      logger.info('Resizing image for face detection', { width, height });
      if (width > height) {
        newW = 1000;
        newH = height * (1000 / width);
      } else {
        newW = width * (1000 / height);
        newH = 1000;
      }
    }

    /**
     * Shrink the image until a face is detected or we've tried 10 times
     */
    while (!face && attempt < 10) {
      canvas.width = newW;
      canvas.height = newH;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(image, 0, 0, newW, newH);
      // eslint-disable-next-line no-await-in-loop
      face = await this.detectFace(canvas);
      attempt += 1;
      newW *= 0.8;
      newH *= 0.8;
    }

    return face;
  }

  async saveFaceImage(box: Box) {
    if (!this.videoFrameCanvas) {
      logger.warn('No video frame canvas');
      return;
    }

    if (this.faceImages.length >= 1) {
      return;
    }

    const { width, height } = this.videoFrameCanvas;

    const tempCanvas = document.createElement('canvas');
    const ctx = tempCanvas.getContext('2d');

    // Set canvas size to match video dimensions
    tempCanvas.width = width;
    tempCanvas.height = height;

    if (ctx) {
      // Draw video frame to the new canvas
      ctx.drawImage(this.videoFrameCanvas, 0, 0, width, height);

      const blob = await new Promise<Blob | null>((resolve) => {
        tempCanvas.toBlob((b) => {
          resolve(b);
        }, 'image/png', 1);
      });

      if (!blob) {
        logger.warn('No blob');
        return;
      }

      logger.info('Saving face image');
      this.faceImages.push(blob);

      // clear canvas and redraw with just the bounding box of the face
      ctx.clearRect(0, 0, width, height);

      // Draw only the bounded region to the new canvas
      const [x, y, w, h] = box;
      ctx.drawImage(this.videoFrameCanvas, x, y, w, h, 0, 0, w, h);

      const blob2 = await new Promise<Blob | null>((resolve) => {
        tempCanvas.toBlob((b) => {
          resolve(b);
        }, 'image/png', 1);
      });

      if (!blob2) {
        logger.warn('No blob');
        return;
      }

      logger.info('Saving face bounding box image', { box });
      this.faceBoxImages.push(blob2);
    }
  }

  async submitFaceImagesAge() {
    if (this.faceBoxImages.length === 0) {
      logger.warn('No face images to submit');
      return;
    }

    logger.info('Submitting bounding box face images for age prediction', { numberOfFaces: this.faceBoxImages.length });

    const formData = new FormData();

    formData.append('files', this.faceBoxImages[0]);
    formData.append('emblem_state', this.emblemState);

    try {
      const { data } = await axios.post('/api/safe-passage/v1/verification/ages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      logger.info('Age prediction response', data);
      const a = data.age_estimate || 0;
      this.ages = [a];
      this.sessionId = data.session_id;
    } catch (error) {
      console.error('Failed to submit face images', error);
      logger.warn('Failed to submit face images', { error: getMessageFromError(error) });
    }
  }

  async submitFaceImagesLiveness() {
    if (this.faceImages.length === 0) {
      logger.warn('No face images to submit');
      return false;
    }

    logger.info('Submitting face images for liveness', { numberOfFaces: this.faceImages.length });

    const formData = new FormData();
    formData.append('selfie', this.faceImages[0]);
    formData.append('emblem_state', this.emblemState);

    try {
      const { data } = await axios.post('/api/safe-passage/v1/verification/liveness', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { success, liveness_result: result } = data;
      logger.info('Liveness response', data);
      return success && result;
    } catch (error) {
      console.error('Failed to submit face images', error);
      logger.warn('Failed to submit face images', { error: getMessageFromError(error) });
      return false;
    }
  }

  breakRecursiveAgePrediction() {
    logger.info('Breaking recursive age prediction');
    this.endRecursion = true;
  }
}

export default HumanAgePrediction;
