import React, { FormEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useFetchConfig from 'services/useFetchConfig';
import Logo from 'components/Logo';
import axios from 'services/api/axios';
import useVerificationStore from 'stores/verificationStore';

function LinkTo({ children, href }: { children?: any; href: string }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="text-blue-600 underline"
    >
      {children}
    </a>
  );
}

function SignupRoute() {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const state = searchParams.get('state') ?? '';
  const appId = searchParams.get('appId') ?? '';
  const userId = searchParams.get('userId') ?? '';
  const authorityId = searchParams.get('authorityId') ?? '';
  const { emblemHost } = useFetchConfig();
  const networkId = useVerificationStore((s) => s.networkId);
  const [alreadySignedUp, setAlreadySignedUp] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [countdown, setCountdown] = useState(8);

  // Check for missing query parameters
  if (!state || !appId || !userId || !authorityId) {
    return (
      <div className="bg-gray-100 text-slate-700 w-full h-full flex items-center justify-center">
        <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-10 max-w-md text-center">
          <h1 className="text-3xl font-bold mb-6">Missing Information</h1>
          <p className="text-gray-600 mb-4">
            It seems like some information is missing. Please start the signup process from the
            <b> Emblem Requestors </b>
            {' '}
            page.
          </p>
          <a href={`${emblemHost}/requestors`} className="text-blue-600 underline">
            Emblem Requestors
          </a>
        </div>
      </div>
    );
  }

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const { data, status } = await axios.post(
      '/api/safe-passage/v1/signup',
      {
        state,
        appId,
        userId,
        authorityId,
        billing: {
          companyName: formData.get('billingCompanyName'),
          contactEmail: formData.get('billingContactEmail'),
          address: formData.get('billingAddress'),
          phoneNumber: formData.get('phoneNumber'),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-network-id': networkId,
        },
      },
    );

    if (data.message === 'Duplicate signup') {
      setAlreadySignedUp(true);
      return;
    }

    if (status === 200) {
      setIsSuccess(true);

      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            window.close();
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  if (isSuccess) {
    return (
      <div className="bg-white text-slate-700 w-full h-full flex items-center justify-center">
        <div className="text-center p-8">
          <div className="mb-4 text-green-600 text-6xl">✓</div>
          <h1 className="text-2xl font-bold mb-4">{t('signup.successTitle')}</h1>
          <p className="text-gray-600 mb-2">{t('signup.successMessage')}</p>
          <p className="text-gray-500">
            {t('signup.windowClosing', { seconds: countdown })}
          </p>
        </div>
      </div>
    );
  }

  if (alreadySignedUp) {
    return (
      <div className="bg-white text-slate-700 w-full h-full flex items-center justify-center">
        <div className="text-center p-8">
          <h1 className="text-2xl font-bold mb-4">{t('signup.alreadySignedUp')}</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white text-slate-700 w-full min-h-screen">
      <div className="flex flex-col md:flex-row w-full">
        {/* Left Panel */}
        <div className="w-full md:w-1/2 p-6 flex flex-col justify-center min-h-screen">
          <div className="w-full max-w-2xl px-4 mx-auto">
            <div className="flex items-center justify-start gap-3 bg-slate-800 p-4 shadow-sm shadow-[#ff9933] rounded-lg w-full mb-12">
              <Logo className="w-80" />
            </div>

            <h1 className="text-2xl font-bold mb-4">{t('signup.addBillingInfo')}</h1>
            <p className="text-gray-500 mb-8 text-lg">{t('signup.billingDescription')}</p>

            <div className="flex flex-col gap-6 mb-8 text-lg">
              <div className="flex items-center gap-2">
                <div className="w-10 h-8 rounded-full bg-green-100 flex items-center justify-center">
                  <span className="text-green-600">✓</span>
                </div>
                <p>{t('signup.noCreditCardRequired')}</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-10 h-8 rounded-full bg-green-100 flex items-center justify-center">
                  <span className="text-green-600">✓</span>
                </div>
                <p>{t('signup.accessibleThroughEmblem')}</p>
              </div>
            </div>

            <div className="mt-8">
              <div className="flex items-center gap-2">
                <span className="text-gray-600">{t('signup.needHelp')}</span>
                <a href="mailto:support@safepassage.com" className="text-blue-600 underline">
                  {t('signup.contactSupport')}
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Right Panel - Form */}
        <div className="w-full md:w-1/2 border-t md:border-t-0 md:border-l border-gray-200 p-6 flex items-center justify-center md:min-h-screen">
          <div className="w-full max-w-2xl px-4 mx-auto py-8 md:py-0">
            <form onSubmit={handleFormSubmit} className="space-y-6 w-full">
              <div>
                <label htmlFor="billingCompanyName" className="block text-sm font-medium text-gray-700">
                  {t('signup.companyNameLabel')}
                </label>
                <input
                  type="text"
                  id="billingCompanyName"
                  name="billingCompanyName"
                  placeholder={t('signup.companyNamePlaceholder')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                  required
                />
              </div>

              <div>
                <label htmlFor="billingContactEmail" className="block text-sm font-medium text-gray-700">
                  {t('signup.billingEmailLabel')}
                </label>
                <input
                  type="email"
                  id="billingContactEmail"
                  name="billingContactEmail"
                  placeholder={t('signup.billingEmailPlaceholder')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">{t('signup.billingEmailHelper')}</p>
              </div>

              <div>
                <label htmlFor="billingAddress" className="block text-sm font-medium text-gray-700">
                  {t('signup.billingAddressLabel')}
                </label>
                <input
                  type="text"
                  id="billingAddress"
                  name="billingAddress"
                  placeholder={t('signup.billingAddressPlaceholder')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">{t('signup.billingAddressHelper')}</p>
              </div>

              <div>
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                  {t('signup.phoneNumberLabel')}
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder={t('signup.phoneNumberPlaceholder')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                />
                <p className="mt-1 text-sm text-gray-500">{t('signup.phoneNumberHelper')}</p>
              </div>

              <div className="flex items-start">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  className="mt-1 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-secondary"
                  required
                />
                <label htmlFor="terms" className="ml-2 block text-sm text-gray-500">
                  <Trans
                    i18nKey={t('signup.termsAgreement')}
                    components={{
                      anchor1: <LinkTo href="https://safepassage.webflow.io/terms" />,
                      anchor2: <LinkTo href="https://safepassage.webflow.io/#pricing" />,
                    }}
                  />
                </label>
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-800 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  {t('signup.completeSignup')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupRoute;
