import { create } from 'zustand';

export interface DocumentScannerConfig {
  detectTimeout: number;
}

export interface AgeDetectionConfig {
  delay: number;
  idleTimeout: number;
  minFaces: number;
  maxFaces: number;
  disableAgePrediction: boolean;
  useBackendAgePrediction: boolean;
}

export interface FaceMatchConfig {
  threshold: number;
  keyFaceThreshold: number,
  keyFaceMismatchLimit: number,
}

export interface FaceValidationConfig {
  faceScore: number,
  liveScore: number,
  realScore: number,
}

export interface LivenessCheckConfig {
  name: string,
  numAngles: number,
  percentToComplete: number,
  proximity: number,
  minYawValue: number,
  minPitchValue: number,
  traffic?: number,
}

export interface ServerModelConfig {
  agePrediction: boolean;
  livenessCheck: boolean;
  idCheck: boolean;
  ageThreshold: number;
}

export interface HumanConfigValues {
  ageDetection: AgeDetectionConfig,
  faceMatch: FaceMatchConfig,
  faceValidation: FaceValidationConfig,
  documentScanner: DocumentScannerConfig,
  livenessCheck: LivenessCheckConfig,
  serverModelConfig: ServerModelConfig;
}

export interface DebugConfig {
  logFaceResult: boolean,
  logFaceAngles: boolean,
  logFaceEmotion: boolean,
  logFaceMatch: boolean,
  logInvalidFace: boolean,
  logValidFace: boolean,
  mugshot: boolean,
  displayKeyFace: boolean,
  displaySimilarity: boolean,
  displayTopSimilarityFace: boolean,
  infiniteFaceCollection: boolean,
  displayAgePredictionFace: boolean,
}

export interface ModelConfig {
  url: string,
  traffic: number,
  name: string,
  executionProviders: string[],
  dimensions: [number, number],
  tensorShape: [number, number, number, number],
  outputKey: string,
  passingValue: number,
  normalizePixelData: boolean,
}

export interface PublicConfig {
  emblemHost: string,
  configLogs: boolean,
  requireManualReviewPhoto: boolean,
  uploadHost: string,
  clientId: string,
  keycloakBaseUrl: string,
  realm: string,
  useLicenseMask: boolean,
  allowLicenseUpload: boolean,
  country: string,
  todaysDate: string,
  models: ModelConfig[],
  testVariant: string| null,
}

export interface ConfigStore {
  emblemHost: string;
  configLogs: boolean;
  requireManualReviewPhoto: boolean;
  setPublicConfig: (publicConfig: Partial<PublicConfig>) => void;
  supportWebsiteUrl: string | null;
  redirectUrl: string | null;
  keycloakBaseUrl: string;
  realm: string;
  clientId: string;
  uploadHost: string;
  useLicenseMask: boolean;
  allowLicenseUpload: boolean;
  country: string;
  todaysDate: string;
  models: ModelConfig[];
  testVariant: string| null;
  allowSkip: boolean;
  humanConfig: HumanConfigValues;
  debug: DebugConfig;
  forceAu10tixDoubleCheck: boolean,
  setSupportWebsiteUrl: (supportWebsiteUrl: string | null) => void;
  setRedirectUrl: (redirectUrl: string | null) => void;
  setAllowSkip: (allowSkip: boolean) => void;
  setAgeDetectionConfig: (humanConfig: Partial<AgeDetectionConfig>) => void;
  setFaceMatchConfig: (humanConfig: FaceMatchConfig) => void;
  setDocumentScannerConfig: (humanConfig: DocumentScannerConfig) => void;
  setFaceValidationConfig: (humanConfig: FaceValidationConfig) => void;
  setLivenessCheckConfig: (humanConfig: LivenessCheckConfig) => void;
  setDebugConfig: (update: Partial<DebugConfig>) => void;
  setForceAu10tixDoubleCheck: (forceAu10tixDoubleCheck: boolean) => void,
  setServerModelConfig: (serverModelConfig: ServerModelConfig) => void;
}

const useConfigStore = create<ConfigStore>((set, get) => ({
  emblemHost: '',
  configLogs: false,
  keycloakBaseUrl: '',
  uploadHost: '',
  realm: '',
  clientId: '',
  todaysDate: '',
  setEmblemHost: (emblemHost: string) => set({ emblemHost }),
  requireManualReviewPhoto: false,
  useLicenseMask: true,
  allowLicenseUpload: true,
  country: '',
  models: [],
  testVariant: null,
  allowSkip: false,
  setAllowSkip: (allowSkip: boolean) => set({ allowSkip }),
  supportWebsiteUrl: null,
  redirectUrl: null,
  setSupportWebsiteUrl: (supportWebsiteUrl: string | null) => set({ supportWebsiteUrl }),
  setRedirectUrl: (redirectUrl: string | null) => set({ redirectUrl }),
  forceAu10tixDoubleCheck: false,
  setForceAu10tixDoubleCheck: (forceAu10tixDoubleCheck: boolean) => set({ forceAu10tixDoubleCheck }),
  humanConfig: {
    ageDetection: {
      delay: 20,
      idleTimeout: 20_000,
      minFaces: 5,
      maxFaces: 25,
      disableAgePrediction: false,
      useBackendAgePrediction: false,
    },
    faceMatch: {
      // Human js considers 0.5 a match but ID photos tend to be old and blurry
      threshold: 0.35,
      keyFaceThreshold: 0.5,
      keyFaceMismatchLimit: 20,
    },
    livenessCheck: {
      name: 'standard',
      // 360 degrees circle will be divided into numAngles parts
      numAngles: 18,

      // percent of circle that must be completed to be complete to pass the check
      percentToComplete: 0.25,

      // degrees
      proximity: 10,

      // pitch or yaw must be at least this value to be considered turned enough from center
      minPitchValue: 0.2,
      minYawValue: 0.2,
    },
    faceValidation: {
      faceScore: 1,
      liveScore: 0.8,
      realScore: 0.5,
    },
    documentScanner: {
      detectTimeout: 60_000,
    },
    serverModelConfig: {
      agePrediction: false,
      livenessCheck: false,
      idCheck: false,
      ageThreshold: 25,
    },
  },
  debug: {
    logFaceResult: false,
    logFaceAngles: false,
    logFaceEmotion: false,
    logFaceMatch: true,
    logInvalidFace: true,
    logValidFace: false,
    mugshot: false,
    displayKeyFace: false,
    keyFaceMismatchThreshold: 10,
    displaySimilarity: false,
    displayTopSimilarityFace: false,
    infiniteFaceCollection: false,
    displayAgePredictionFace: false,
  },
  setAgeDetectionConfig: (ageDetectionConfig: Partial<AgeDetectionConfig>) => {
    const c = get().humanConfig;
    const newConfig = {
      ...c,
      ageDetection: {
        ...c.ageDetection,
        ...ageDetectionConfig,
      },
    };
    set({ humanConfig: newConfig });
  },
  setDocumentScannerConfig: (documentScannerConfig: DocumentScannerConfig) => {
    const newConfig = {
      ...get().humanConfig,
      documentScanner: documentScannerConfig,
    };
    set({ humanConfig: newConfig });
  },
  setFaceMatchConfig: (faceMatchConfig: FaceMatchConfig) => {
    const newConfig = {
      ...get().humanConfig,
      faceMatch: faceMatchConfig,
    };
    set({ humanConfig: newConfig });
  },
  setFaceValidationConfig: (faceValidationConfig: FaceValidationConfig) => {
    const newConfig = {
      ...get().humanConfig,
      faceValidation: faceValidationConfig,
    };
    set({ humanConfig: newConfig });
  },
  setLivenessCheckConfig: (livenessCheckConfig: LivenessCheckConfig) => {
    const newConfig = {
      ...get().humanConfig,
      livenessCheck: livenessCheckConfig,
    };
    set({ humanConfig: newConfig });
  },
  setDebugConfig: (update: Partial<DebugConfig>) => {
    const newConfig: DebugConfig = {
      ...get().debug,
      ...update,
    };
    set({ debug: newConfig });
  },
  setPublicConfig: (publicConfig) => {
    const pc = get();
    set({
      ...pc,
      ...publicConfig,
    });
  },
  setServerModelConfig: (serverModelConfig) => {
    const newConfig = {
      ...get().humanConfig,
      serverModelConfig,
    };
    set({ humanConfig: newConfig });
  },
}));

export default useConfigStore;
