import { useEffect, useState } from 'react';
import useConfigStore, { ModelConfig } from 'stores/configStore';
import getErrorMessage from 'utils/getErrorMessage';
import selectLivenessConfig from 'utils/selectLivenessConfig';
import useCheckHealthStatus from 'hooks/useCheckHealthStatus';
import axios from './api/axios';
import logger from './logger';

const defaultModels: ModelConfig[] = [
  {
    name: '2025-02-22',
    url: 'onnx/best_model_fourth_age_fa3acbf2_2025-02-22.static.with_runtime_opt.ort',
    traffic: -1,
    executionProviders: ['wasm'],
    dimensions: [128, 128],
    tensorShape: [1, 3, 128, 128],
    outputKey: 'sigmoid',
    passingValue: 0.6,
    normalizePixelData: true,
  },
  {
    name: 'threshold_27',
    url: 'onnx/ssrnet_2pass_83c05c_-_threshold 27_-_1.65_ybpo_7.35_obpy.ort',
    traffic: 1,
    executionProviders: ['webgl'],
    dimensions: [96, 96],
    tensorShape: [1, 96, 96, 3],
    outputKey: 'pred_a',
    passingValue: 27,
    normalizePixelData: false,
  },
];

const defaultLivenessCheckConfig = [
  {
    name: 'standard',
    numAngles: 18,
    percentToComplete: 0.25,
    proximity: 10,
    minYawValue: 0.2,
    minPitchValue: 0.2,
    traffic: 1,
  },
];

function useFetchConfig() {
  const [loading, setLoading] = useState(true);
  const emblemHost = useConfigStore((state) => state.emblemHost);
  const configLogs = useConfigStore((state) => state.configLogs);
  const clientId = useConfigStore((state) => state.clientId);
  const realm = useConfigStore((state) => state.realm);
  const keycloakBaseUrl = useConfigStore((state) => state.keycloakBaseUrl);
  const uploadHost = useConfigStore((state) => state.uploadHost);
  const requireManualReviewPhoto = useConfigStore((state) => state.requireManualReviewPhoto);
  const testVariant = useConfigStore((state) => state.testVariant);
  const setPublicConfig = useConfigStore((state) => state.setPublicConfig);
  const setLivenessCheckConfig = useConfigStore((state) => state.setLivenessCheckConfig);
  const setServerModelConfig = useConfigStore((state) => state.setServerModelConfig);
  const [configLoaded, setConfigLoaded] = useState(false);

  const serviceHealthy = useCheckHealthStatus();

  useEffect(() => {
    if (serviceHealthy === null) {
      return;
    }
    if (emblemHost) {
      // If we have emblemHost that means the config has already been fetched
      setLoading(false);
      return;
    }

    const fetchUrl = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get('/api/safe-passage/v1/config');

        const models = data.models && data.models.length
          ? data.models
          : defaultModels;

        const filteredModels = models.filter((m:ModelConfig) => m.traffic > 0);

        const livenessConfigs = data.livenessCheckConfigs && data.livenessCheckConfigs.length
          ? data.livenessCheckConfigs
          : defaultLivenessCheckConfig;

        // testVariantTraffic is the percentage of traffic that will be sent to variant B
        // which is the new feature that we want to test
        const testVariantTraffic = data.testVariantTraffic || 0;
        let variant = Math.random() >= testVariantTraffic ? 'A' : 'B';

        // switch back to A if the server is not healthy
        variant = serviceHealthy ? variant : 'A';

        setPublicConfig({
          emblemHost: data.emblemHost || '',
          configLogs: data.configLogs || false,
          requireManualReviewPhoto: data.requireManualReviewPhoto || false,
          uploadHost: data.uploadHost || '',
          clientId: data.safePassageClientId || '',
          keycloakBaseUrl: data.safePassageKeycloakBaseUrl || '',
          realm: data.safePassageRealm || '',
          useLicenseMask: data.useLicenseMask ?? true,
          allowLicenseUpload: data.allowLicenseUpload ?? true,
          country: data.country || 'US',
          todaysDate: data.todaysDate || '',
          models: filteredModels,
          testVariant: variant,
        });

        const isServer = variant === 'B';

        const livenessCheck = selectLivenessConfig(livenessConfigs);
        logger.info('Selected liveness check config', { ...livenessCheck });
        logger.info('Selected test variant', { testVariant: variant });
        setLivenessCheckConfig(livenessCheck);
        setServerModelConfig({
          agePrediction: isServer,
          livenessCheck: isServer,
          idCheck: isServer,
          ageThreshold: data.ageThreshold || 99,
        });
        setConfigLoaded(true);
      } catch (error) {
        const errorMessage = getErrorMessage(error as Error, 'Error fetching public config');
        logger.error('Error fetching public config', { errorMessage });
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUrl();
  }, [emblemHost, serviceHealthy, setPublicConfig, setLivenessCheckConfig, setServerModelConfig]);

  return {
    emblemHost,
    configLogs,
    loading,
    clientId,
    realm,
    keycloakBaseUrl,
    uploadHost,
    requireManualReviewPhoto,
    testVariant,
    configLoaded,
  };
}

export default useFetchConfig;
