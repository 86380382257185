import React, { useEffect, useMemo } from 'react';
import Lottie, { EventListener } from 'react-lottie';
import liveCheck from 'components/lottie/live-check.json';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import mixpanel from 'services/mixpanel';
import Button from './Button';

const lottieOptions = { animationData: liveCheck, autoplay: true, loop: false };

interface Props {
  animationComplete: boolean;
  setAnimationComplete: (value: boolean) => void;
  setShowDemo: (value: boolean) => void;
  allowSkip: boolean;
}

function Demo({
  animationComplete, setAnimationComplete, setShowDemo, allowSkip,
}: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    mixpanel.trackEvent({ event: 'Safe Passage Demo Start' });
  }, []);

  const eventListeners: EventListener[] = useMemo(() => ([{
    eventName: 'complete',
    callback: () => {
      setAnimationComplete(true);
    },
  }]), [setAnimationComplete]);

  return (
    <div className="flex flex-col items-center gap-6 w-full">
      <div className="max-w-md text-center">
        <div className="mb-4 h-64">
          <Lottie
            options={lottieOptions}
            eventListeners={eventListeners}
            width={215}
          />
        </div>
        <Text
          size="lg"
          className="mb-4 font-bold"
        >
          {t('demo.howItWorks')}
        </Text>
        <Text className="mb-4">
          {t('demo.simplyTurnHead')}
        </Text>

      </div>
      {allowSkip && (
        <div className="space-y-3">
          {animationComplete
            ? (
              <Button
                className="mb-2"
                variant="primary"
                onClick={() => {
                  setShowDemo(false);
                  mixpanel.trackEvent({ event: 'Safe Passage Demo Continue' });
                }}
              >
                {t('demo.continue')}
              </Button>
            )
            : (

              <Button
                className="mb-2 text-blue-300"
                variant="text"
                onClick={() => {
                  setShowDemo(false);
                  mixpanel.trackEvent({ event: 'Safe Passage Demo Skip' });
                }}
              >
                {t('demo.skip')}
              </Button>
            )}
        </div>
      )}
    </div>
  );
}

export default Demo;
