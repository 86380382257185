import { useEffect } from 'react';
import logger from 'services/logger';
import mixpanel from 'services/mixpanel';
import useConfigStore from 'stores/configStore';
import useVerificationStore from 'stores/verificationStore';

function useSetupMixpanel() {
  const networkId = useVerificationStore((state) => state.networkId);
  const livenessCheck = useConfigStore((state) => state.humanConfig.livenessCheck);
  const testVariant = useConfigStore((state) => state.testVariant);

  useEffect(() => {
    if (!networkId || !livenessCheck || !testVariant) return;
    const searchParams = new URLSearchParams(window.location.search);
    const emblemStateParam = searchParams.get('emblemState');
    const tagRequestIdParam = searchParams.get('tagRequestId');
    const projectKeyParam = searchParams.get('projectKey');
    const companyNameParam = searchParams.get('companyName');
    mixpanel.init(networkId === 137, emblemStateParam || '');
    const values = {
      emblemState: emblemStateParam || '',
      tagRequestId: tagRequestIdParam || '',
      projectKey: projectKeyParam || '',
      companyName: companyNameParam || '',
      livenessCheckVariant: livenessCheck.name,
      testVariant,
    };

    logger.info('Setting up Mixpanel', values);
    mixpanel.register(values);
    mixpanel.trackEvent({ event: 'Safe Passage Start' });
    mixpanel.trackEvent({ event: 'Selected Liveness Check', livenessCheck: livenessCheck.name });
    mixpanel.trackEvent({ event: 'Selected Test Variant', variant: testVariant });
  }, [networkId, livenessCheck, testVariant]);
}

export default useSetupMixpanel;
