const getMedianValue = (array: number[] = []) => {
  if (!Array.isArray(array)) {
    return 0;
  }

  if (array.length === 0) {
    return 0;
  }

  if (array.length === 1) {
    return array[0];
  }

  const mid = Math.floor(array.length / 2);
  const nums = [...array].sort((a, b) => a - b);

  return nums.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export default getMedianValue;
