import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

/**
 * Persist the verification state in local storage so that when
 * the user is redirected to keycloak for login or account creation
 * the verification state is not lost.
 */
type DocumentKind = 'id' | 'passport' | '';
type DocumentType = 'us-id' | 'non-us-id' | 'passport' | '';

interface VerificationStore {
  tagRequestId: string | null;
  emblemState: string;
  projectKey: string;
  tag: string;
  companyName: string;
  mobileDeepLink: string | null;
  documentKind: DocumentKind;
  documentType: DocumentType,
  documentCountry: string;
  verificationFailure: boolean;
  shouldOCR: boolean;
  networkId: number | null;
  companySlug: string | null;
  setTagRequestId: (tagRequestId: string) => void;
  reset: () => void;
  setEmblemState: (newEmblemState: string) => void;
  setProjectKey: (newProjectKey: string) => void;
  setTag: (newTag: string) => void;
  setCompanyName: (newCompanyName: string) => void;
  setMobileDeepLink: (mobileDeepLink: string) => void;
  setDocumentKind: (docKind: DocumentKind) => void;
  setDocumentType: (documentType: DocumentType) => void;
  setDocumentCountry: (documentCountry: string) => void;
  setVerificationFailure: (verificationFailure: boolean) => void;
  setShouldOCR: (shouldOCR: boolean) => void;
  setNetworkId: (networkId: number | null) => void;
  setCompanySlug: (companySlug: string) => void;
}

const useVerificationStore = create<VerificationStore>()(
  devtools(
    persist(
      (set) => ({
        tagRequestId: null,
        emblemState: '',
        projectKey: '',
        tag: '',
        companyName: '',
        mobileDeepLink: null,
        documentType: '',
        documentKind: '',
        documentCountry: 'US',
        verificationFailure: false,
        shouldOCR: false,
        networkId: null,
        companySlug: null,
        setTagRequestId: (tagRequestId) => set({ tagRequestId }),
        setEmblemState: (newEmblemState) => set({ emblemState: newEmblemState }),
        setTag: (newTag) => set({ tag: newTag }),
        setProjectKey: (newProjectKey) => set({ projectKey: newProjectKey }),
        setCompanyName: (newCompanyName) => set({ companyName: newCompanyName }),
        setMobileDeepLink: (mobileDeepLink) => set({ mobileDeepLink }),
        setDocumentKind: (documentKind) => set({ documentKind }),
        setDocumentType: (documentType) => set({ documentType }),
        setDocumentCountry: (documentCountry) => set({ documentCountry }),
        setVerificationFailure: (verificationFailure) => set({ verificationFailure }),
        setShouldOCR: (shouldOCR) => set({ shouldOCR }),
        setNetworkId: (networkId) => set({ networkId }),
        setCompanySlug: (companySlug) => set({ companySlug }),
        reset: () => {
          set({
            tagRequestId: null,
            emblemState: '',
            projectKey: '',
            tag: '',
            companyName: '',
            mobileDeepLink: null,
            documentType: '',
            documentKind: '',
            documentCountry: 'US',
            verificationFailure: false,
            shouldOCR: false,
            networkId: null,
            companySlug: '',
          });
        },
      }),
      {
        name: 'verification-storage',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useVerificationStore;
