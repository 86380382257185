import {
  VerifyIdErrorCodes, FaceMatchErrorCodes, Au10tixErrorCodes, AgePredictionErrorCodes,
} from 'types/logger';

/* eslint-disable max-len */
export default {
  invalidRequest: 'Invalid request',
  pageNotFound: 'Page not found',
  and: 'and',
  or: 'or',
  instruction: {
    useIdOrLicense: 'Please utilize any state-issued drivers license or identification card.',
  },
  loadingPage: {
    title: 'We\'re getting the secure and private verification process set up',
    complete: 'COMPLETE!',
    personal: 'Your personal information is never collected, stored, or shared',
    settingUp: 'We are setting up our age prediction system. This may take a few seconds',
    onDevice: "By doing age prediction right here on your device, we don't transfer any biometric data",
  },
  entry: {
    title: 'Secure Age Verification',
    usingAI: 'Using advanced AI we will attempt to verify your age by appearance first.',
    noBio: 'No biometric data is transmitted or stored.',
    ifUnsuccessful: 'If unsuccessful, we\'ll guide you through a secure, anonymous ID verification process.',
    start: 'Start Verification',
  },
  cameraEntry: {
    title: "Let's Get You Verified",
    firstAttempt: 'We\'ll first attempt to verify your age through a quick selfie scan, utilizing AI technology.',
    noBio: 'No biometric data is transmitted or stored',
    ready: 'Ready to proceed?',
    allowCamera: 'Allow Camera Access',
  },
  prepareIdVerification: {
    title: 'Congratulations on your youthful appearance!',
    description: 'We need a bit more to verify your age, so please use a government-issued ID or passport.',
    restEasy: 'Rest easy. Your personal info is always protected with us.',
    continue: 'Continue',
  },
  footer: {
    title: 'Verified by',
    terms: 'Terms & Conditions',
    privacy: 'Privacy Policy',
    biometric: 'Biometric',
    copyright: 'Copyright',
    rights: 'All rights reserved.',
  },
  error: {
    mintTag: 'Error minting tag',
    generic: 'Something went wrong. Please try again.',
    noFileSelected: 'No file selected',
    dynamic: {
      [VerifyIdErrorCodes.ERR_LOAD_IMAGE]: 'Something went wrong. Please try again.',
      [VerifyIdErrorCodes.ERR_DETECT_NONE]: 'Could not find any ID card in the provided photo. Please ensure that the photo contains a clear and valid ID card for identification.',
      [VerifyIdErrorCodes.ERR_DETECT_MULTIPLE]: 'Detected multiple ID cards, please provide only one ID card for identification.',
      [VerifyIdErrorCodes.ERR_DETECT_MUGSHOT]: 'No photo suitable for identification was found within the identification card. Please try again.',
      [VerifyIdErrorCodes.ERR_DETECT_DATE]: 'We could not verify the dates in your photo. Please upload a new photo and make sure they are visible',
      [VerifyIdErrorCodes.ERR_DETECT_DATE_THRESHOLD]: 'No date(s) detected within age threshold.',
      [VerifyIdErrorCodes.ERR_DETECT_FACE]: 'Error resizing ID photo and detecting face',
      [VerifyIdErrorCodes.ERR_DETECT_DATE_FUTURE]: 'Could not find a valid expiration date. Please try again.',

      [FaceMatchErrorCodes.ERR_FACE_MATCH_ID]: 'The photo on the ID does not match your appearance. Please try again.',
      [FaceMatchErrorCodes.ERR_MATCHING_FACES]: 'There was an error matching your face. Please try again.',

      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE]: 'Au10tix age not 18+.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC]: 'Au10tix ID not authentic.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED]: 'Au10tix ID expired.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_MAX_ATTEMPTS]: 'Au10tix max attempts reached.',

      [AgePredictionErrorCodes.ERR_RECURSIVE_AGE_PREDICTION]: 'Error predicting age.',
      [AgePredictionErrorCodes.ERR_SET_VIDEO_SOURCE]: 'Error setting video source. Please check your camera.',
      [AgePredictionErrorCodes.ERR_NO_FACE_TIMEOUT]: 'Could not predict age because no face was detected.',
    },
  },
  failure: {
    title: 'Verification Failed!',
    errorMessage: 'There was a problem trying to verify your age. Please try again.',
    tryAgain: 'Try Again',
    submitForReview: 'Submit for Manual Review',
    returnToSite: 'Return to site',
  },
  success: {
    title: 'Congratulations, your age has been successfully verified.',
    description: 'You have successfully verified your age.',
    continueToSite: 'Continue to Site',
  },
  tryAgain: {
    title: 'Unable to Verify Age',
    body: "We couldn't complete the verification. Please try again in good lighting, facing directly toward the camera.",
    tryAgain: 'Try Again',
  },
  appeal: {
    processing: 'Processing...',
    confirm: {
      photo: {
        title: 'Confirm Photo',
        description: "To proceed with manual verification, please confirm the photo of your face below.If you're not satisfied with the current image, you can choose to retake to ensure the best results.",
        confirmAndProceed: 'Confirm and Proceed',
        retakeNewPhoto: 'Retake Photo',
        takePhoto: 'Take Photo',
        errorAndRetake: 'Error taking photo. Please retake photo.',
      },
      id: {
        title: 'Confirm Your ID',
        description: "Now, let's confirm the image of your ID. Ensure all details are clear and legible. If you believe a different photo would be more appropriate, you can upload a new one now.",
        confirmAndProceed: 'Confirm and Proceed',
        uploadNewIdPhoto: 'Upload New ID Photo',
        uploadPhoto: 'Upload a photo',
      },
    },
    success: {
      title: 'Success',
      description: "Thank you! We got your information, and we'll get back to you soon.",
    },
    manualSubmission: {
      title: 'Manual Verification Submission',
      description: 'Your biometric information will be used solely for age verification, as outlined in our terms and conditions and privacy policy.',
      submitForManualReview: 'Submit for Manual Review',
      emailAddress: 'Email Address',
      message: 'Message (Optional)',
      agreedToTermsAndConditionsAndPrivacyPolicy: 'By checking this box you agree to our',
      termsAndConditions: 'Terms and Conditions',
      privacyPolicy: 'Privacy Policy',
    },
  },
  a10tix: {
    title: 'Verify Identity',
    pending: 'Verification Pending',
    verificationPending: 'Verification Pending',
    verificationPendingDescription: 'This may take up to one minute',
    takingLongTimeMessage: 'This is taking longer that normal. To speed up the process you can upload a new photo of your ID. Please make sure the photo is not blurry and is well lit.',
    uploadNewPhoto: 'Upload a new photo',
    submit: 'Submit',
    takeAPhoto: 'Take a photo',
    takeADifferentPhoto: 'Take a different photo',
  },
  cameraPermission: {
    description: 'Camera permission is required to use this feature.',
    denied: 'It appears you have denied camera access for this webpage. You will need to update your browser settings and refresh the page.',
    goBack: 'Go Back',
  },
  detectionMessage: {
    'Please move your face into the frame': 'Please move your face into the frame',
    'Turn your head to the left': 'Turn your head to the left',
    'Turn your head to the right': 'Turn your head to the right',
    'Tilt your head up': 'Tilt your head up',
    'Tilt your head down': 'Tilt your head down',
    'Look straight into the camera': 'Look straight into the camera',
    'Move your head slowly to fill in the circle': 'Move your head slowly to fill in the circle',
  },
  reviewRequest: {
    title: 'Review Request',
    email: 'Email',
    message: 'Message',
    idImage: 'ID Image',
    userImage: 'User Image',
    requestApproved: 'Request Approved',
    requestDenied: 'Request Denied',
    approve: 'Approve',
    deny: 'Deny',
    processing: 'Processing...',
  },
  licenses: {
    title: 'License Photos',
    loading: 'Loading...',
    deleteAll: 'Delete All',
  },
  license: {
    emblemState: 'Emblem State:',
    ocrStatus: 'OCR Status:',
    fileId: 'File ID:',
    createdAt: 'Created At:',
    uploadReason: 'Upload Reason:',
    status: 'Status:',
    ocrFailureReason: 'OCR Failure Reason:',
  },
  idPhotoGuidelines: {
    title: 'Please take note of the following:',
    placeId: 'Place ID on a flat, single-color surface.',
    ensureGlareFree: 'Ensure a glare-free, well-lit photo.',
    allEdges: 'All ID edges must be in the frame.',
  },
  takePhoto: {
    rotateGuide: 'Rotate guide',
    setIdFlat: 'Make sure ID is on a flat surface, clearly visible and all edges within the frame.',
    cancel: 'Cancel',
  },
  uploadPhoto: {
    uploadPhoto: 'Upload Photo',
  },
  verifyId: {
    noFaceDetected: 'No face detected in uploaded photo',
    idShouldBeVisible: ' Your ID should be fully visible and readable in the photo.',
    submit: 'Submit',
    next: 'Next',
    retakePhoto: 'Retake Photo',
    rescanFace: 'Re-scan Face',
    verificationInProgress: 'Verification in progress...',
    pleaseWait: 'Please wait while your photo is being verified. This should only take a few seconds.',
    chooseId: 'Choose Your ID Type',
    idCard: 'Drivers License or State ID card',
    germanIdCard: 'German National ID Card',
    passport: 'Passport',
    selectCountry: 'Select your document\'s country of issuance:',
    continue: 'Continue',
    frontOfId: 'Take a photo of the front of your ID',
    faceNoMatch: 'The face in the ID does not match your appearance.',
    informationSecure: 'Your information remains secure and confidential.',
    scanFailure: {
      title: 'Verification Unsuccessful',
      unable: 'We\'re sorry, but we couldn\'t verify your age with the document provided. Let\'s give it another try.',
    },
  },
  demo: {
    howItWorks: 'How it works',
    simplyTurnHead: 'Simply turn your head slowly in a counter clockwise direction when prompted.',
    skip: 'Skip demo',
    continue: 'Continue to verify',
  },
  agePrediction: {
    getReady: 'Get ready...',
    analyzing: 'Analyzing...',
  },
  human: {
    errorInitializing: 'Error initializing',
    errorLoadingModel: 'Error loading model',
    pleaseCloseWindowAndTryAgain: 'Please close this window and try again',
    incompatibleWebview: 'The current browser is not supported. If you are in a webview, please open the link in a browser.',
  },
  verifyIdInfo: {
    passport: {
      title: 'Scan the identification page of your passport',
      instruction: [
        '• Lay document flat and ensure good lighting',
        '• Fit entire document within the frame',
        '• Align code at bottom with guide',
      ].join('<br/>'),
    },
    'non-us-id': {
      title: 'Scan the back of your ID',
      instruction: [
        '• Lay document flat and ensure good lighting',
        '• Fit entire document within the frame',
        '• Align code at bottom with guide',
      ].join('<br/>'),
    },
    'us-id': {
      title: 'Scan the back of your ID',
      instruction: 'Place the barcode on the back of your ID <strong>within the frame</strong>. Once in position, it will be scanned automatically.',
    },
    scanBarcode: 'Find and scan this barcode',
    cantFindBarcode: 'Can\'t find a barcode on your ID? Tap "Skip" to continue.',
    skipBarcode: 'Skip',
  },
  faceAngleMessage: {
    moveFace: 'Please move your <br /> face into the frame',
    lookStraight: 'Look straight into <br /> the camera',
    turnHead: 'Looking good! <br /> Keep turning to complete the circle',
    keepTurningClockwise: 'Turn your head in a <br /> clockwise direction',
  },
  signup: {
    addBillingInfo: 'Add Billing Information',
    billingDescription: 'To complete your integration with SafePassage, please provide your billing details. This information will be used for invoicing purposes related to SafePassage services linked with Emblem.',
    noCreditCardRequired: 'No credit card is required at this time. You will be invoiced based on usage.',
    accessibleThroughEmblem: 'All your usage details will be accessible through Emblem.',
    companyNameLabel: 'Company Name (or Full Name if not representing an organization)',
    companyNamePlaceholder: 'e.g., Acme Corp / John Doe',
    billingEmailLabel: 'Billing Contact Email',
    billingEmailPlaceholder: 'e.g., billing@acmecorp.com',
    billingEmailHelper: 'Invoices and billing information will be sent here.',
    billingAddressLabel: 'Billing Address',
    billingAddressPlaceholder: 'Street Address, City, State, Postal Code, Country',
    billingAddressHelper: 'Please enter a complete address for invoice purposes.',
    phoneNumberLabel: 'Phone Number (Optional)',
    phoneNumberPlaceholder: 'e.g., +1 234 567 8902',
    phoneNumberHelper: 'For follow-ups related to billing, if needed.',
    termsAgreement: 'The Client agrees that the electronic form of these <anchor1>Terms</anchor1> and any actions taken by the Client to accept these Terms satisfy any legal requirements for agreements in writing under applicable laws, including but not limited to electronic signatures and electronic records laws. By agreeing to these Terms, the Client also agrees to our <anchor2>Pricing</anchor2>.',
    completeSignup: 'Complete Sign-Up',
    needHelp: 'Need help?',
    contactSupport: 'Contact us at support@safepassage.com',
    successTitle: 'Sign up Complete!',
    successMessage: 'Thank you for signing up. Your account has been created successfully.',
    windowClosing: 'This window will close in {{seconds}} seconds...',
    alreadySignedUp: 'You have already signed up for SafePassage.',
  },
  serverModel: {
    noBio: 'No biometric data is stored.',
  },
};
