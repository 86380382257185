import React, { useState, useEffect } from 'react';
import Text from 'components/Text';
import Heading from 'components/Heading';
import logger from 'services/logger';
import useConfigStore from 'stores/configStore';
import useVerificationStore from 'stores/verificationStore';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import loadImage from 'utils/loadImage';
import { DocumentScanner, DocumentScanResult } from 'components/DocumentScanner';
import { useNavigate, Navigate } from 'react-router-dom';
import { getMessageFromError } from 'utils/errorMessage';
import { useTranslation } from 'react-i18next';
import { useHumanAgePredictor } from 'contexts/HumanAgePredictionContext';
import { VerifyIdErrorCodes, VerifyIdInfoErrorCodes, loggerMessages } from 'types/logger';
import Button from 'components/Button';
import MainLayout from 'layouts/MainLayout';
import isEighteenYearsOld from 'utils/isEighteenYearsOld';
import mixpanel from 'services/mixpanel';
import isOffscreenCanvasSupported from 'utils/isOffscreenCanvasSupported';

const CLIENT_SIDE_TAG = '18+_idai';

function VerifyIdInfo() {
  const [scanImageDataURL, setScanImageDataURL] = useState<string | undefined>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const human = useHumanAgePredictor();

  const faces = useAgeDetectionStore((state) => state.faces);

  const tag = useVerificationStore((state) => state.tag);
  const documentType = useVerificationStore((state) => state.documentType);
  const detectTimeout = useConfigStore((state) => state.humanConfig.documentScanner.detectTimeout);
  const todaysDate = useConfigStore((state) => state.todaysDate);
  const documentCountry = useVerificationStore((state) => state.documentCountry);
  const isRetry = useAgeDetectionStore((s) => s.isRetry);
  const setVerificationFailure = useVerificationStore((state) => state.setVerificationFailure);

  useEffect(() => {
    /**
     * Terminate human worker when we reach this stage.
     * We will re-init before doing the face match.
     */
    if (!human) return;
    human.terminateHumanWorker();
  }, [human]);

  useEffect(() => {
    logger.setMessageAggregate('barcodeScanner', documentType === 'us-id' ? 'pdf417' : 'mrz');
    logger.info(loggerMessages.verifyIdInfo.info.start, {
      aggregates: { documentType, documentCountry },
    });
    logger.info(loggerMessages.phases.info.verifyBarcode, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
    mixpanel.trackBarcodeScan({
      event: 'Barcode Scan Start',
      documentType,
      documentCountry,
    });
  }, []);

  const onFrame = (imageDataURL: string) => {
    /**
     * Submit URL Encoded frame to the server for debugging purposes.
     */
    logger.info(loggerMessages.verifyIdInfo.info.frameCaptured, {
      aggregates: { documentType, documentCountry, imageDataURL },
    });
  };

  const onSuccess = (result: DocumentScanResult) => {
    /**
     * I haven't tested every PDF417 in the world, so it's possible
     * it doesn't extract a birthDate on one. MRZ scans will have it
     * because I know the document structure. PDF417 is a bit of a mystery.
     * I'd say there's a 99.99% chance of having a birthDate extracted though.
     */
    if (!result.birthDate) {
      logger.warn(loggerMessages.verifyIdInfo.error.invalidBirthDate, {
        aggregates: {
          birthDate: result.birthDate || '',
          expirationDate: result.expirationDate || '',
          detectedType: result.type,
          documentType,
          errorCode: VerifyIdInfoErrorCodes.FAIL_INVALID_BIRTH_DATE,
        },
      });
      mixpanel.trackBarcodeScan({
        event: 'Barcode Scan Error',
        expirationDate: result.expirationDate || '',
        detectedType: result.type,
        documentType,
        errorCode: VerifyIdInfoErrorCodes.FAIL_INVALID_BIRTH_DATE,
      });

      if (tag === CLIENT_SIDE_TAG) {
        setVerificationFailure(true);
        navigate('/select-doc-type');
        return;
      }

      navigate('/verify-id?barcodeFailure=true');
      return;
    }
    const isEighteen = isEighteenYearsOld(result.birthDate);
    const birthDate = new Date(result.birthDate);
    const minAge = 18; // Note: not using tags for this stage.

    const date = new Date(todaysDate).getTime();

    const age = Math.floor((date - birthDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25));

    logger.info(loggerMessages.verifyIdInfo.info.scanResult, {
      aggregates: {
        birthDate: result.birthDate,
        expirationDate: result.expirationDate || '',
        detectedType: result.type,
        documentType,
        minAge,
        age,
        isEighteen,
      },
    });
    mixpanel.trackBarcodeScan({
      event: 'Barcode Scan Complete',
      birthDate: result.birthDate || '',
      expirationDate: result.expirationDate || '',
      detectedType: result.type,
      documentType,
      age,
    });

    if (!isEighteen) {
      logger.warn(loggerMessages.verifyIdInfo.warn.ageBelowThreshold, {
        aggregates: {
          birthDate: result.birthDate,
          expirationDate: result.expirationDate || '',
          detectedType: result.type,
          documentType,
          minAge,
          age,
          isEighteen,
          errorCode: VerifyIdInfoErrorCodes.FAIL_BIRTH_DATE_BELOW_THRESHOLD,
        },
      });
      mixpanel.trackBarcodeScan({
        event: 'Barcode Scan Underage',
        birthDate: result.birthDate || '',
        expirationDate: result.expirationDate || '',
        detectedType: result.type,
        documentType,
        age,
        errorCode: VerifyIdInfoErrorCodes.FAIL_BIRTH_DATE_BELOW_THRESHOLD,
      });

      navigate(`/exit?error=3&errorCode=${VerifyIdErrorCodes.ERR_DETECT_DATE_THRESHOLD}`);
      return;
    }

    /**
     * The processing of the scan image needs to be done in a useEffect
     * to capture whether or not a user exits this route or else async states
     * can cause chaos.
     */
    if (documentType === 'passport') {
      setScanImageDataURL(result.imageDataURL);
      return;
    }

    navigate('/exit');
  };

  const onError = (err: Error) => {
    const errorMessage = getMessageFromError(err);
    if (Number(errorMessage) === VerifyIdInfoErrorCodes.ERR_BARCODE_SCAN_TIMEOUT) {
      logger.warn(loggerMessages.verifyIdInfo.error.scan, {
        errorMessage: 'Barcode scan timed out.',
        aggregates: {
          documentType,
          errorCode: VerifyIdInfoErrorCodes.ERR_BARCODE_SCAN_TIMEOUT,
        },
      });
      mixpanel.trackBarcodeScan({
        event: 'Barcode Scan Timeout',
        errorMessage,
        documentType,
        errorCode: VerifyIdInfoErrorCodes.ERR_BARCODE_SCAN_TIMEOUT,
      });
    } else {
      logger.warn(loggerMessages.verifyIdInfo.error.scan, {
        errorMessage,
        aggregates: {
          documentType,
          errorCode: VerifyIdInfoErrorCodes.ERR_SCAN_DOCUMENT,
        },
      });
      mixpanel.trackBarcodeScan({
        event: 'Barcode Scan Error',
        errorMessage,
        documentType,
        errorCode: VerifyIdInfoErrorCodes.ERR_SCAN_DOCUMENT,
      });
    }

    /**
     * If the document is a passport or the tag is matched,
     * don't fallback to ocr. Update the failed verification state
     * and redirect back to document select to try again.
     */
    if (documentType === 'passport' || tag === CLIENT_SIDE_TAG) {
      setVerificationFailure(true);
      navigate('/select-doc-type');
    } else {
      navigate('/verify-id?barcodeFailure=true');
    }
  };

  useEffect(() => {
    if (!scanImageDataURL || !human || !faces?.length) {
      if (scanImageDataURL) {
        console.warn('Scanned Image but missing required values', { human: !human, faces: !faces?.length });
      }
      return undefined;
    }

    let exited: boolean | undefined;

    /**
     * Re-init the Human worker
     * Load the scanned passport image
     * Detect the face in the scan image
     * Match the detected face with the faces from Age Estimation
     */

    // If the browser supports OffscreenCanvas, use the worker version of human
    // If not, human is still available in the main thread and does not need to be re-initialized
    const humanInit = () => {
      if (isOffscreenCanvasSupported()) {
        return human.initHumanWorker();
      }
      return Promise.resolve();
    };
    humanInit()
      .then(() => loadImage(scanImageDataURL))
      .then((documentScanImage) => human.detectFace(documentScanImage))
      .then((faceFromScan) => {
        if (exited) {
          return undefined;
        }

        if (faceFromScan && faceFromScan.embedding) {
          return human.matchFaces(faceFromScan, faces);
        }

        return Promise.reject(new Error('Unable to detect face within document image.'));
      })
      .then((result) => {
        if (exited) {
          return undefined;
        }

        if (result?.pass) {
          navigate('/exit');
          return undefined;
        }

        return Promise.reject(new Error('Faces did not match with document provided.'));
      })
      .catch((err: Error) => {
        if (exited) {
          return;
        }

        logger.warn(loggerMessages.verifyIdInfo.error.faceMatch, {
          errorMessage: getMessageFromError(err),
          aggregates: {
            documentType,
            errorCode: VerifyIdInfoErrorCodes.FAIL_MATCHING_FACES,
          },
        });
        mixpanel.trackBarcodeScan({
          event: 'Barcode Scan Error',
          errorMessage: getMessageFromError(err),
          documentType,
          errorCode: VerifyIdInfoErrorCodes.FAIL_MATCHING_FACES,
        });

        setVerificationFailure(true);
        navigate('/select-doc-type');
      });

    return () => {
      /**
       * The user backed away or something. Update the exited state so the async
       * calls don't continue on.
       */
      exited = true;
    };
  }, [
    faces,
    human,
    navigate,
    scanImageDataURL,
    setVerificationFailure,
    documentType,
  ]);

  if (!documentType) {
    /**
     * If for whatever reason the documentType wasn't assigned,
     * go back.
     */
    return (
      <Navigate to="/select-doc-country" />
    );
  }

  return (
    <MainLayout
      includeBackButton
      backButtonHandler={() => {
        logger.info('User backed away from document scan.', {
          aggregates: { documentType, documentCountry },
        });
        navigate('/select-doc-type');
      }}
    >
      <div className="w-full items-center flex flex-col flex-1">
        <Heading className="text-xl mb-4 text-center">
          {t(`verifyIdInfo.${documentType}.title`)}
        </Heading>
        <DocumentScanner
          className="mb-8"
          onSuccess={onSuccess}
          onError={onError}
          onFrame={onFrame}
          timeout={documentType === 'passport' ? (60_000 * 10) : detectTimeout}
          guidelines={documentType}
          detect={documentType === 'us-id' ? 'pdf417' : 'mrz'}
        />
        <Text className="text-center leading-snug mb-4 p-4 bg-slate-800 rounded-lg">
          <span dangerouslySetInnerHTML={{ __html: t(`verifyIdInfo.${documentType}.instruction`) }} />
        </Text>
        {documentType === 'us-id' ? (
          <div className="flex flex-col items-center">
            <Text className="text-center font-semibold mb-1">
              {t('verifyIdInfo.scanBarcode')}
            </Text>
            <div className="w-32 bg-white p-1 rounded-md">
              <img src="/images/pdf417-example.jpg" alt="Barcode" className="w-full h-auto" />
            </div>
          </div>
        ) : null}
        {documentType !== 'passport' && (
          <>
            <Text className="text-center leading-snug mb-4 mt-8">
              {t('verifyIdInfo.cantFindBarcode')}
            </Text>
            <Button
              variant="secondary"
              onClick={() => {
                logger.info('Skip barcode scan');
                navigate('/verify-id?barcodeFailure=true');
              }}
            >
              {t('verifyIdInfo.skipBarcode')}
            </Button>
          </>
        )}
      </div>
    </MainLayout>
  );
}

export default VerifyIdInfo;
