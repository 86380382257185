/**
 * Check if the user is 18 years old or older
 */
function isEighteenYearsOld(dateString: string, returnAge = false) {
  const today = new Date();
  const birthDate = new Date(dateString);
  if (Number.isNaN(birthDate.getTime())) {
    return returnAge ? 0 : false;
  }
  let ageInFullYears = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    ageInFullYears -= 1;
  }

  if (returnAge) {
    return ageInFullYears;
  }

  return ageInFullYears >= 18;
}

export default isEighteenYearsOld;
