import React, { useEffect } from 'react';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Text from 'components/Text';
import { Link } from 'react-router-dom';
import logger from 'services/logger';
import { loggerMessages } from 'types/logger';
import { useTranslation } from 'react-i18next';
import MainLayout from 'layouts/MainLayout';
import localStorageUtil from 'utils/localStorageUtil';
import mixpanel from 'services/mixpanel';
import useConfigStore from 'stores/configStore';

function CameraEntryRoute() {
  const { t, i18n } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const testVariant = useConfigStore((state) => state.testVariant);

  useEffect(() => {
    const lng = searchParams.get('lng') ?? localStorageUtil.getValues().lng ?? '';
    if (lng) { i18n.changeLanguage(lng); }
  }, [i18n]);

  useEffect(() => {
    logger.info(loggerMessages.phases.info.cameraEntry, { aggregates: { phaseProgress: true } });
    logger.flush();
    mixpanel.trackEvent({ event: 'Safe Passage Landing' });
  }, []);

  return (
    <MainLayout includeBackButton={false}>
      <div className="flex flex-col items-center gap-6 w-full">
        <div className="max-w-md text-center">
          <Heading className="mb-4 text-2xl">
            {t('cameraEntry.title')}
          </Heading>
          <Text size="lg" className="mb-4">
            {t('cameraEntry.firstAttempt')}
          </Text>
          <div className="flex justify-center">
            <img src="/images/live-check.svg" alt="face" className="h-44 w-auto mb-4" />
          </div>
          <Text size="lg" className="mb-4 font-bold">
            {testVariant === 'B' ? t('serverModel.noBio') : t('cameraEntry.noBio')}
          </Text>
          <Text size="lg" className="mb-4">
            {t('cameraEntry.ready')}
          </Text>
          <div className="space-y-3">
            <Button
              onClick={() => {
                mixpanel.trackEvent({ event: 'Allow Camera Access', cameraAccessGranted: true });
              }}
              component={Link}
              to="/verify-age"
              className="mb-2"
              variant="primary"
              id="allow-camera-link"
            >
              {t('cameraEntry.allowCamera')}
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default CameraEntryRoute;
