import React, {
  createContext, useContext, useState, useEffect, useMemo,
} from 'react';
import { detect } from 'detect-browser';
import useConfigStore from 'stores/configStore';
import ErrorLayout from 'layouts/ErrorLayout';
import HumanAgePrediction from 'services/human/Human';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import LoadingPage from 'components/LoadingPage';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import useProgressStore from 'stores/progressStore';
import { Navigate } from 'react-router-dom';
import mixpanel from 'services/mixpanel';
import { getMessageFromError } from 'utils/errorMessage';
import logger from 'services/logger';
import useVerificationStore from 'stores/verificationStore';

const HumanContext = createContext<HumanAgePrediction | null>(null);

function HumanAgePredictionProvider({ children }: { children: React.ReactNode }) {
  const humanConfig = useConfigStore((state) => state.humanConfig);
  const debugConfig = useConfigStore((state) => state.debug);
  const models = useConfigStore((state) => state.models);
  const setSimilarity = useAgeDetectionStore((state) => state.setSimilarity);
  const setFaces = useAgeDetectionStore((state) => state.setFaces);
  const setCentered = useAgeDetectionStore((state) => state.setCentered);
  const setFaceDetected = useAgeDetectionStore((state) => state.setFaceDetected);
  const setAge = useAgeDetectionStore((state) => state.setAge);
  const setLiveScore = useAgeDetectionStore((state) => state.setLiveScore);
  const setRealScore = useAgeDetectionStore((state) => state.setRealScore);
  const resetAgeDetectionStore = useAgeDetectionStore((state) => state.reset);
  const setAngles = useAgeDetectionStore((state) => state.setAngles);
  const setStatus = useAgeDetectionStore((state) => state.setStatus);
  const setStep = useAgeDetectionStore((state) => state.setStep);
  const setDisableAgePrediction = useConfigStore((state) => state.setAgeDetectionConfig);
  const disableAgePrediction = useConfigStore((state) => state.humanConfig.ageDetection.disableAgePrediction);
  const setAgeResult = useAgeDetectionStore((state) => state.setAgeResult);
  const setLoopTime = useAgeDetectionStore((state) => state.setLoopTime);
  const setSubmittingLoading = useAgeDetectionStore((state) => state.setSubmittingLoading);
  const displaySimilarity = useConfigStore((state) => state.debug.displaySimilarity);
  const emblemState = useVerificationStore((state) => state.emblemState);

  const setProgress = useProgressStore((state) => state.setProgress);
  const { t } = useTranslation();

  // State
  const [initialized, setInitialized] = useState(false);
  const [onnxInitialized, setOnnxInitialized] = useState(false);
  const [human, setHuman] = useState<HumanAgePrediction | null>(null);
  const [error, setError] = useState<boolean | null>(null);
  const [onnxError, setOnnxError] = useState<string | null>(null);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [agePredictionErrorNext, setAgePredictionErrorNext] = useState('');
  const [modelPercentLoaded, setModelPercentLoaded] = useState(0);
  const browser = detect();

  const serverAgePrediction = humanConfig.serverModelConfig.agePrediction;
  const serverLivenessCheck = humanConfig.serverModelConfig.livenessCheck;
  const serverIdCheck = humanConfig.serverModelConfig.idCheck;

  const fullServer = serverAgePrediction && serverLivenessCheck && serverIdCheck;

  useEffect(() => {
    if (models.length === 0) {
      return () => {};
    }

    setInitialized(false);
    setHuman(null);
    const humanInstance = new HumanAgePrediction(humanConfig, debugConfig, models, emblemState);
    humanInstance.on('initialized', () => {
      setInitialized(true);
    });

    humanInstance.on('onnxInitialized', () => {
      setOnnxInitialized(true);
    });

    humanInstance.on('initError', (err) => {
      const errorMessage = getMessageFromError(err);
      mixpanel.trackEvent({ event: 'Human Initialization Error', errorMessage });
      setError(true);
    });

    humanInstance.on('initOnnxError', (err) => {
      /**
       * If ONNX fails to initialize we disable age prediction
       * and the user must upload their ID to verify their age.
       * The user still goes through the liveness check and we
       * match their face readings from Human with their ID
       *
       * This usually happens when the users is on an older OS
       * like Android 10 or iOS 14
       */
      const errorMessage = getMessageFromError(err);
      mixpanel.trackEvent({ event: 'Onnx Load Error', errorMessage });
      setOnnxError(errorMessage || 'ONNX failed to initialize');
      setDisableAgePrediction({ disableAgePrediction: true });
    });

    humanInstance.on('error', (errorMessage: string) => {
      mixpanel.trackEvent({ event: 'Human Error', errorMessage });
      setError(true);
    });

    humanInstance.on('modelLoadProgress', (progressData) => {
      if (!progressData.human.total) {
        return;
      }

      if (!disableAgePrediction && !progressData.onnx.total) {
        return;
      }
      const loaded = progressData.human.loaded + progressData.onnx.loaded;
      const total = progressData.human.total + progressData.onnx.total;
      const percent = Math.ceil((loaded / total) * 100);
      setModelPercentLoaded(percent);
    });

    const setUp = async () => {
      await humanInstance.init();
      setHuman(humanInstance);
    };
    setUp();
    return () => {
      humanInstance.agePredictor?.onnxWorker?.worker.terminate();
      humanInstance.humanWorker?.worker.terminate();
    };
  }, [humanConfig, debugConfig, setDisableAgePrediction, models, disableAgePrediction, fullServer]);

  useEffect(() => {
    if (!human) {
      return;
    }
    human.on('realScore', (score) => {
      setRealScore(score);
    });

    human.on('liveScore', (score) => {
      setLiveScore(score);
    });

    human.on('step', (s) => {
      setStep(s);
    });

    human.on('progress', (p) => {
      setProgress(p);
    });

    human.on('faceDetected', (f) => {
      setFaceDetected(f);
    });

    human.on('centered', (c) => {
      setCentered(c);
    });

    human.on('angles', (a) => {
      setAngles(a);
    });

    human.on('status', (s) => {
      setStatus(s);
    });

    human.on('age', (a) => {
      setAge(a);
    });

    human.on('faces', (f) => {
      setFaces(f);
    });

    human.on('faceMatchResult', (result) => {
      if (displaySimilarity) setSimilarity(result.similarity);
    });

    human.on('agePredictionError', ({ nextLocation }) => {
      setAgePredictionErrorNext(nextLocation);
    });

    human.on('ageResult', (r) => {
      setAgeResult(r);
    });

    human.on('loopTime', (time) => {
      setLoopTime(time);
    });

    human.on('reset', () => {
      resetAgeDetectionStore();
      setProgress(0);
    });

    human.on('submittingLoading', (loading) => {
      setSubmittingLoading(loading);
    });
  }, [human]);

  const hasError = !!error || !!onnxError;
  const isWebview = browser?.name.toLowerCase() === 'ios-webview';

  useEffect(() => {
    // Logger warn for webview. We display a message to the user letting them know their browser is incompatible
    if (isWebview) {
      if (error) {
        logger.warn('User could not initialize Human in webview', { humanError: error, onnxError });
      }

      if (onnxError) {
        logger.warn('User could not initialize ONNX in webview', { onnxError });
      }
      return;
    }

    // Log error so we get an alert and can investigate
    if (error) {
      logger.error('User could not initialize Human', { humanError: error });
    }

    if (onnxError) {
      logger.warn('User could not initialize ONNX', { onnxError });
    }
  }, [error, onnxError, isWebview]);

  const value = useMemo(() => human, [human]);

  if (agePredictionErrorNext) {
    return (
      <Navigate to={agePredictionErrorNext} replace />
    );
  }

  if ((error && onnxError) || onnxError?.includes('no available backend') || (hasError && isWebview)) {
    // Likely due to incompatible browser
    return (
      <ErrorLayout>
        <div className="flex flex-col justify-center content-center text-center w-full">
          <h2 className="text-2xl mb-4">{t('human.errorInitializing')}</h2>
          <Text>{t('human.incompatibleWebview')}</Text>
        </div>
      </ErrorLayout>
    );
  }

  if (error) {
    return (
      <ErrorLayout>
        <div className="flex flex-col justify-center content-center text-center w-full">
          <h2 className="text-2xl mb-4">{t('human.errorInitializing')}</h2>
          <Text>{t('human.pleaseCloseWindowAndTryAgain')}</Text>
        </div>
      </ErrorLayout>
    );
  }

  const onnxDone = onnxInitialized || disableAgePrediction;

  if (human === null || !initialized || !onnxDone || !animationComplete) {
    return (
      <LoadingPage
        initialized={initialized && onnxDone}
        setAnimationComplete={setAnimationComplete}
        animationComplete={animationComplete}
        percentLoaded={modelPercentLoaded}
      />
    );
  }

  return (
    <HumanContext.Provider value={value}>
      {children}
    </HumanContext.Provider>
  );
}

function useHumanAgePredictor() {
  const value = useContext(HumanContext);
  if (value === null) {
    throw new Error('useHumanAgePredictor must be used within a HumanProvider');
  }
  return value;
}

export { useHumanAgePredictor, HumanAgePredictionProvider };
