import React, { Fragment, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Modal from './Modal';
import useConfigStore from '../stores/configStore';

function AdminModal() {
  const [isOpen, setIsOpen] = useState(false);
  const allowSkip = useConfigStore((state) => state.allowSkip);
  const setAllowSkip = useConfigStore((state) => state.setAllowSkip);
  const faceMatch = useConfigStore((state) => state.humanConfig.faceMatch);
  const faceMatching = useConfigStore((state) => state.humanConfig.faceMatch);
  const setFaceMatchingConfig = useConfigStore((state) => state.setFaceMatchConfig);
  const faceValidation = useConfigStore((state) => state.humanConfig.faceValidation);
  const setFaceValidationConfig = useConfigStore((state) => state.setFaceValidationConfig);
  const debug = useConfigStore((state) => state.debug);
  const setDebugConfig = useConfigStore((state) => state.setDebugConfig);
  const forceAu10tixDoubleCheck = useConfigStore((state) => state.forceAu10tixDoubleCheck);
  const setForceAu10tixDoubleCheck = useConfigStore((state) => state.setForceAu10tixDoubleCheck);
  const serverModelConfig = useConfigStore((state) => state.humanConfig.serverModelConfig);
  const setServerModelConfig = useConfigStore((state) => state.setServerModelConfig);

  useEffect(() => {
    const keys = {
      a: false,
      d: false,
      m: false,
      i: false,
      n: false,
    };
    const handleKeyDown = (e: KeyboardEvent) => {
      const key = e.key?.toLowerCase() as keyof typeof keys;

      // Update key states
      if (Object.hasOwn(keys, key)) {
        keys[key] = true;

        // Check if all keys are pressed
        if (keys.a && keys.d && keys.m && keys.i && keys.n) {
          setIsOpen(true);
        }
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      const key = e.key?.toLowerCase() as keyof typeof keys;

      // Update key states
      if (Object.hasOwn(keys, key)) {
        keys[key] = false;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <Fragment>
      <button
        type="button"
        className="fixed top-0 right-0 z-50 p-4"
        onClick={() => setIsOpen(true)}
      >
        Debug
      </button>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Admin"
      >
        <div className="text-white">
          <button type="button" tabIndex={-1} className="absolute top-4 right-4" onClick={() => setIsOpen(false)}>
            <XMarkIcon width={25} />
          </button>
          <div className="flex flex-col content-start mb-4">
            <label htmlFor="allowSkip">
              Allow Skip
              <input
                className="m-2"
                type="checkbox"
                id="allowSkip"
                checked={allowSkip}
                onChange={(e) => setAllowSkip(e.target.checked)}
              />
            </label>
          </div>

          <div className="mb-4">

            <h2 className="text-lg font-semibold text-white">Server Side</h2>
            <div className="flex flex-col content-start">
              <label htmlFor="ageThreshold">
                Age Threshold
              </label>
              <input
                className="m-2 text-black"
                type="number"
                id="ageThreshold"
                value={serverModelConfig.ageThreshold}
                onChange={(e) => setServerModelConfig({
                  ...serverModelConfig,
                  ageThreshold: +e.target.value,
                })}
              />
            </div>
            <div className="flex flex-col content-start">
              <label htmlFor="agePrediction">
                Age Prediction
                <input
                  className="m-2"
                  type="checkbox"
                  id="agePrediction"
                  checked={serverModelConfig.agePrediction}
                  onChange={(e) => setServerModelConfig({
                    ...serverModelConfig,
                    agePrediction: e.target.checked,
                  })}
                />
              </label>
            </div>
            <div className="flex flex-col content-start">
              <label htmlFor="livenessCheck">
                Liveness Check
                <input
                  className="m-2"
                  type="checkbox"
                  id="livenessCheck"
                  checked={serverModelConfig.livenessCheck}
                  onChange={(e) => setServerModelConfig({
                    ...serverModelConfig,
                    livenessCheck: e.target.checked,
                  })}
                />
              </label>
            </div>
            <div className="flex flex-col content-start">
              <label htmlFor="idCheck">
                ID Check
                <input
                  className="m-2"
                  type="checkbox"
                  id="idCheck"
                  checked={serverModelConfig.idCheck}
                  onChange={(e) => setServerModelConfig({
                    ...serverModelConfig,
                    idCheck: e.target.checked,
                  })}
                />
              </label>
            </div>
          </div>

          <h2 className="text-lg font-semibold text-white">Face Match</h2>
          <div className="flex flex-col content-start">
            <label htmlFor="faceMatchThreshold">
              Threshold
            </label>
            <input
              className="mb-4 text-black"
              type="number"
              id="faceMatchThreshold"
              value={faceMatching.threshold}
              onChange={(e) => setFaceMatchingConfig({
                ...faceMatch,
                threshold: Number(e.target.value),
              })}
              min={0}
              max={1}
              step={0.01}
            />
          </div>

          <h2 className="text-lg font-semibold text-white">Face Validation</h2>
          <div className="flex flex-col content-start">
            <label htmlFor="liveScore">
              Live Score Threshold
            </label>
            <input
              className="mb-4 text-black"
              type="number"
              id="liveScore"
              value={faceValidation.liveScore}
              onChange={(e) => setFaceValidationConfig({
                ...faceValidation,
                liveScore: Number(e.target.value),
              })}
              min={0}
              max={1}
              step={0.01}
            />

            <label htmlFor="realScore">
              Real Score Threshold
            </label>
            <input
              className="mb-4 text-black"
              type="number"
              id="realScore"
              value={faceValidation.realScore}
              onChange={(e) => setFaceValidationConfig({
                ...faceValidation,
                realScore: Number(e.target.value),
              })}
              min={0}
              max={1}
              step={0.01}
            />
          </div>

          <h2 className="text-lg font-semibold text-white">Debug Logs</h2>
          <div className="flex flex-col content-start">
            <label htmlFor="logFaceResult">
              Face Result
              <input
                className="m-2"
                type="checkbox"
                id="logFaceResult"
                checked={debug.logFaceResult}
                onChange={(e) => setDebugConfig({ logFaceResult: e.target.checked })}
              />
            </label>

            <label htmlFor="logFaceAngles">
              Face Rotation
              <input
                className="m-2"
                type="checkbox"
                id="logFaceAngles"
                checked={debug.logFaceAngles}
                onChange={(e) => setDebugConfig({ logFaceAngles: e.target.checked })}
              />
            </label>

            {/* <label htmlFor="logFaceEmotion">
              Emotion
              <input
                className="m-2"
                type="checkbox"
                id="logFaceEmotion"
                checked={debug.logFaceEmotion}
                onChange={(e) => setDebugConfig({ logFaceEmotion: e.target.checked })}
              />
            </label> */}

            <label htmlFor="logFaceMatch">
              Similarity
              <input
                className="m-2"
                type="checkbox"
                id="logFaceMatch"
                checked={debug.logFaceMatch}
                onChange={(e) => setDebugConfig({ logFaceMatch: e.target.checked })}
              />
            </label>

            <label htmlFor="logInvalidFace">
              Invalid Face
              <input
                className="m-2"
                type="checkbox"
                id="logInvalidFace"
                checked={debug.logInvalidFace}
                onChange={(e) => setDebugConfig({ logInvalidFace: e.target.checked })}
              />
            </label>

            <label htmlFor="logValidFace">
              Valid Face
              <input
                className="m-2"
                type="checkbox"
                id="logValidFace"
                checked={debug.logValidFace}
                onChange={(e) => setDebugConfig({ logValidFace: e.target.checked })}
              />
            </label>

            <label htmlFor="displayKeyFace">
              Display Key Face
              <input
                className="mb-4"
                type="checkbox"
                id="displayKeyFace"
                checked={debug.displayKeyFace}
                onChange={(e) => setDebugConfig({ displayKeyFace: e.target.checked })}
              />
            </label>

            <label htmlFor="displayAgePredictionFace">
              Display Age Prediction Face
              <input
                className="mb-4"
                type="checkbox"
                id="displayAgePredictionFace"
                checked={debug.displayAgePredictionFace}
                onChange={(e) => setDebugConfig({ displayAgePredictionFace: e.target.checked })}
              />
            </label>

            <label htmlFor="displaySimilarity">
              Display Similarity
              <input
                className="mb-4"
                type="checkbox"
                id="displaySimilarity"
                checked={debug.displaySimilarity}
                onChange={(e) => setDebugConfig({ displaySimilarity: e.target.checked })}
              />
            </label>

            <label htmlFor="displayTopSimilarityFace">
              Display Top Similarity Face
              <input
                className="mb-4"
                type="checkbox"
                id="displayTopSimilarityFace"
                checked={debug.displayTopSimilarityFace}
                onChange={(e) => setDebugConfig({ displayTopSimilarityFace: e.target.checked })}
              />
            </label>

            <label htmlFor="infiniteFaceCollection">
              Infinite Face Collection
              <input
                className="mb-4"
                type="checkbox"
                id="infiniteFaceCollection"
                checked={debug.infiniteFaceCollection}
                onChange={(e) => setDebugConfig({ infiniteFaceCollection: e.target.checked })}
              />
            </label>

          </div>
          <h2 className="text-lg font-semibold text-white">Au10tix</h2>
          <div className="flex flex-col content-start">
            <label htmlFor="forceAu10tixDoubleCheck">
              Force Double Check
              <input
                className="m-2"
                type="checkbox"
                id="forceAu10tixDoubleCheck"
                checked={forceAu10tixDoubleCheck}
                onChange={(e) => setForceAu10tixDoubleCheck(e.target.checked)}
              />
            </label>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default AdminModal;
